



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 155px;
$page_wrapper-bannerTop-laptop: 155px;
$page_wrapper-bannerTop-tablet: 155px;
$page_wrapper-bannerTop-mobile: 155px;

.page.contact .banner.top {
	transition: height 1s;
	height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.contact .banner.top .bloc-container {
	transition: line-height 1.8s;
	line-height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}



.page.contact {
    background:#fff;
}


.page.contact .banner.top {
	position: relative;
	.image {
		background-position: 50% 50% !important;
        background-size: cover !important;
	}
	.bloc {
		@include brk($t) {
			padding: 0px 0 0 0;
		}
	}
	.titre {
		// animation: fade 2s;
		font-size:35px;
		font-weight: $bold;
		letter-spacing: 0.5px;
		color:$touche_de_couleur3;
        line-height: 1.35;
		position: relative;
		@include brk($t) { font-size: 35px; }
		@include brk($m) { font-size: 25px; }
	}
	
}









.page.contact .banner.top {
	.image { 
		// animation: fade 1s; 
		background-position: 50% 20%;
	}
}








.page.contact .banner.medium {
	overflow:visible;
	background:#f2f8f8;
	padding-top:0;
	animation:none;
	.banner-content {
		@include brk($t) { padding:0 }
		.content {
			position: relative;
		}
	}
}



.page.contact .col-left {
	min-height: 300px;
	background:#ffffff;
	float: left;
	width:26%;
	z-index: 21;
	// animation: fade 2s;
	@extend .cache-left;
	@extend .cache-bottom-left;
	@include brk($t) { 
		width: 100%; 
		padding-left:7%;
		&:after {display:none;}
	}
	@include brk($m) {  
		width: 100%;  
		padding-left:$padding-mobile;
		padding-right:$padding-mobile;
	}
	.inside {
		padding: 70px 0% 80px 0px;
		text-align: left;
		animation: fade 1s;
		@include brk($t) { 
			padding: 50px 0% 40px 0;
		}
		@include brk($m) { 
			text-align: left; 
			padding: 40px 0% 30px 0;
		}
	}
    .col {
        width:100%;
        @include brk($t) {
            width:50%;
            float: none;
        }
        @include brk($m) {
            width:100%;
        }

        ul {
            display: inline-block;
            width: 100%;
            padding-top: 10px;
            @include brk($t) {
                width: 120%;
            }
            @include brk($tp) {
                width: 140%;
            }
            @include brk($m) {
                width: 100%;
            }
            li {
                margin-bottom: 10px;
                display: inline-block;
                @include brk($t) {
                    width: 50%;
                    float: left;
                }
                @include brk($m) {
                    width: 100%;
                }
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .titre-colonne {
        color:#003359;
        font-size: 25px;
        font-weight: $bold;
        position: relative;
        margin-bottom: 25px;
        @include brk($t) {
            margin-bottom: 35px;
            display: none;
        }
        @include brk($m) {
        }
    }
	.logo-container {
        // float: left;
        margin-top: 15px;
        width: 110px;
        height: 50px;
        position: relative;
        display: inline-block;
        @include brk($t) {
            margin-top: -8px;
        }
        @include brk($m) {
            margin-top: -5px;
        }
        .logo-image {
            display: inline-block;
            height:100%;
            width:100%;
            position: absolute;
            top:5px;
            left:-3px;
            .st0{fill:none;}
            .st1{fill:#00A9B9;}
            .st2{fill:#F18700;}
        }
    }
	.adresses-container {
        padding-bottom:8px;
        .rue, .ville {
            font-size: 15px;
            font-weight: $bold;
            color:#003359;
        }
	}
    .tel-fax-container {
        padding-bottom:8px;
        span {
            display:block;
            color:$touche_de_couleur3;
            font-weight: $bold;
            p, a {
                display: inline;
                margin-left:5px;
                font-size: 15px;
                color:$touche_de_couleur3;
                line-height: 2;
                font-weight: $regular;
            }
        }
        a {
            position: relative;
            &:after {
                content:"";
                display: inline-block;
                position: absolute;
                bottom:1px;
                left:0;
                height:2px;
                width:0;
                opacity: 0;
                transition: opacity 0.5s, width 0.2s;
                background: lighten($touche_de_couleur, 0%);
            }
            &:hover {
                &:after {
                    width:100%;
                    opacity: 1;
                }
            }
        }
    }
	.petit-trait {
		height: 2.5px;
		background:$touche_de_couleur;
		margin-top: 17px;
		margin-bottom:18px;
		width:21px;
	}
	.trait {
		height: 13px;
		border-bottom:1px solid transparentize(#000, 0.9);
		margin-bottom:16px;
		width:80%;
		// @include brk($t) {  display: none }
	}
	.googlemap-container {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
        @include brk($t) { margin-top: 20px; margin-bottom:30px;}
        @include brk($m) { margin-top: 20px; }
        p {
            font-size: 15px;
            font-weight: $bold;
            float: left;
            width:100px;
            margin-top: 13px;
            display: inline-block;
            margin-right: 15px;
            color:$touche_de_couleur3;
            transition: color 1s;
            @include brk($m) { 
                float: left; 
                // margin-left: 20px;
            }
        }
        .icon-container {
            float: left;
            height:54px;
            width: 54px;
            background: transparentize($touche_de_couleur, 0.95);
            border-radius: 50%;
            text-align: center;
            margin-right: -5px;
            transition: transform 0.15s;
            @include brk($m) { float: left; }
            .icon {
                margin-top: 13px;
                width:28px;
                height:28px;
                transition: transform 5s;
            }
            path {
                fill:$touche_de_couleur;
                transition: fill 0.2s;
            }
        }
        &:hover {
            // p {color:$touche_de_couleur2}
            .icon-container {transform: scale(0.98)}
            .icon {transform: scale(1.2)}
            path {
                transition: fill 0.2s;
                fill:$touche_de_couleur2
            }
        }
    }
}




.page.contact .col-right {
	background:#ecf7f8;
	padding: 75px 0px 70px 110px;
	float: right;
	width:74%;
	// animation: fade 1.5s;
    @extend .cache-right;
	@extend .cache-bottom-right;
	@include brk($t) { 
		padding: 60px 7% 50px;
		width: 100%; }
	@include brk($m) {
		padding: 55px 6% 0px;
		padding-left:$padding-mobile;
		padding-right:$padding-mobile;
		width: 100%;
	}
	.format {
		padding: 0px 0 50px 12%;
	    display: inline-block;
	    width: 100%;
	    @include brk ($tablet) {
	        padding: 0 9% 55px;
	    }
	    @include brk ($mobile) {
	        padding: 0 6%;
	        margin-top: 40px;
	        margin-bottom:40px;
	    }
	}
    .titre-section {
        font-size:25px;
        font-weight: $bold;
        color:$touche_de_couleur3;
        letter-spacing: 0px;
        line-height: 1.6;
        position: relative;
        @include breakpoint ($tablet) {
        }
        @include breakpoint ($mobile) {
            font-size:25px;
            padding-right:10%;
        }
    }
}








