


.flexible {
    li:first-child .flex-titre {
        // margin-top: 0!important;
    }
    li:last-child .flex {
        // margin-bottom: 0;
    }
    .banner-content {
    	padding:0;
    	max-width: none;
    }
    .flex {
        display: inline-block;
        position: relative;
        width:100%;
        .titre {
            font-size: 25px;
            line-height: 1.5;
            font-weight: $bold;
            letter-spacing: 0.8px;
            color:#404040;
            padding-bottom:15px;
            @include brk ($m) { font-size: 20px; }
        }
        .chapeau p {
            font-size: 22.5px;
            font-weight: $normal;
            line-height: 1.4;
            color:$touche_de_couleur;
            @include brk($m) {
                font-size: 18px;
            }

        }
        .lire-la-suite {
            margin-top: 20px;
            color:#fff;
            border-radius: 10px;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 2.5px;
            font-weight: $bold;
            padding:20px 30px;
        }
    }
}









.page .flex {
    // .banner-content { padding:0; }

    &.flex-titre-chapeau {
        margin-top: 0px;    
        margin-bottom: 25px;    
        .titre {
            padding-bottom:0px;
        }   
        .titre p {
            font-size: 36px;
            font-weight: $bold;
            line-height: 1.2;
            color:#003359;
            padding-bottom:20px;
            border-bottom: 1px solid transparentize(#000, 0.9);
            margin-bottom: 0px;
            @include brk($t) {
                font-size: 32px;
            }
            @include brk($m) {
                font-size: 21px;
            }
        }
        .chapeau {
            margin-top: 25px;
            p {
                padding-bottom: 0px;
            }
        }
    }
    
    &.flex-texte {margin-bottom: 35px;}

    &.flex-wysiwyg {
        margin-bottom: 40px;
        ul li {
            color:$touche_de_couleur3;
            font-weight: $bold;
            &:before {
                color:$touche_de_couleur3;
            }
        }
        .texte {
            h3, h4 {
                color:$touche_de_couleur3;
                padding-bottom:15px;
                line-height: 1.25;
            }
        }
    }
    
    &.flex-citation {margin-bottom: 60px;}

    &.flex-focus {
        background:transparentize($touche_de_couleur, 0.965);
        position: relative;
        padding: 30px 55px 30px 47px;
        margin-bottom:40px;
        @include brk($m) {
            padding: 25px 35px 30px 30px;
            width:108%;
        }
        .titre {
            font-size: 27px;
            padding-bottom:10px;
            font-weight: $bold;
            color:$touche_de_couleur3;
        }
        &:before {
            content:"";
            display: inline-block;
            height:100%;
            width:6px;
            position: absolute;
            left:0; top:0;
            background:$touche_de_couleur;
        }
        ul {
            padding: 0 0 0 18px;
            li {
                font-size: 15px;
                font-weight: $normal;
                letter-spacing: 1px;
                padding: 11px 0px;
                margin-bottom: 0;
                border-top:1px solid transparentize(#000, 0.9);
                &:first-child {border-top:none;}
                &, &:before {
                    color: $touche_de_couleur;
                }
                &:before {
                    top:10px;
                    left:-12px;
                    font-weight: inherit;
                }
            }
        }
    }


    &.flex-image {
        height:auto;
        margin-bottom: 27px;
        @include brk ($t) { height:auto; }
        @include brk ($m) { height:auto; }
        &.has-lightbox .image {
            cursor:pointer;
        }
    }

    &.flex-image_texte {
        margin-bottom: 50px;
        .col-image {
            width:41%;
            height:auto;
            @include brk ($t) {margin-bottom:35px;width:46%;}
            @include brk ($m) {height:auto; width:100%;}
            .image {
                // border-radius:6px;
                animation: fade 2s;
                @include brk ($m) { 
                    border-radius:0px;
                    width:100%;
                    left:0%;
                }
            }
        }
        .col-texte {
            width:59%;
            padding-left: 8%;
            @include brk ($t) {width:54%; margin-bottom:20px;}
            @include brk ($m) {width:100%;padding-left: 0%;}
        }
    }


    &.flex-texte_image {
        margin-bottom: 80px;
        .col-texte {
            width:60%;
            padding-right:6%;
            @include brk ($t) {margin-bottom:20px;width:50%;}
            @include brk ($m) {width:100%;padding-right:0%;}
            .chapeau {
                position: relative;
                &:before {
                    content:"";
                    display: inline-block;
                    height:2px;
                    width:75px;
                    position: absolute;
                    left:-100px;
                    top:18px;
                    background:$touche_de_couleur;
                    @include brk($l) {
                        width:40px;
                        left:-66px;
                    }
                    @include brk($t) {display:none;}
                }
            }
        }
        .col-image {
            width:39%;
            float: right;
            height:325px;
            @include brk ($t) {margin-bottom:50px;width:46%;}
            @include brk ($m) {height:235px; width:100%;}
            .image {
                border-radius:6px;
                animation: fade 2s;
                @include brk ($m) { 
                    border-radius:0px;
                    width:100%;
                    left:0%;
                }
            }
        }
    }


    &.flex-accordeon {
        .ul-accordeon {
            // margin-bottom:120px;
        }
        .ul-accordeon>li {
            position: relative;
            display: inline-block;
            display: inline-block;
            width:100%;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            .bloc-titre-container {
                cursor:pointer;
                padding-right: 45px;
                display: inline-block;
                width:100%;
                position: relative;
                .bloc-titre {
                    padding: 24px 0 26px;
                }
                &:hover {
                    .icon-container {
                        background:$touche_de_couleur;
                        // transform: scale(1);
                    }
                    .st0{stroke:#fff !important;}
                }
                .date {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    font-weight: $bold;
                    color:$touche_de_couleur;
                    padding-bottom:0;
                    user-select:none;
                    margin-right: 10px;
                    // animation: fade 2s;
                }
                .titre {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    // animation: fade 2s;
                    font-weight: $normal;
                    // user-select:none;
                    color:$touche_de_couleur;
                }
                .chapeau p {
                    font-size: 12px;
                    font-weight: $light;
                    color:#797979;
                    letter-spacing: 0.2px;
                    line-height: 1.8;
                    padding-bottom:0;
                    cursor: pointer;
                }
                .icon-container {
                    display: inline-block;
                    width:38px;
                    height:38px;
                    background:#fff;
                    border-radius:50%;
                    position: absolute;
                    right:0px;
                    top:17px;
                    text-align: center;
                    cursor: pointer;
                    // transform: scale(1.05);
                    transition: transform 0.3s;
                    .icon {
                        width:18px;
                        height:18px;
                        margin-top: 10px;
                        transition: transform 0.2s;
                    }
                    svg {
                        transition: transform 0.3s;
                        transform: scale(0.5);
                    }
                    .st0{fill:none;stroke:#82827f;stroke-width:8;stroke-miterlimit:10;}
                    .hor {transition: opacity 0.2s;}
                }
            }
            .bloc-contenu {
                padding-top: 10px;
                padding-bottom: 20px;
                opacity: 0;
                transition: opacity 0.5s;
                overflow: hidden;
                .image-container {
                    position: relative;
                    height:337px;
                    margin-bottom: 25px;
                    @include brk ($t) { height:290px; }
                    @include brk ($m) { height:118px; }
                    &.has-lightbox {cursor:pointer}
                }
                .col {
                    position: relative;
                    height:auto;
                    .image-col {
                        transform: translate3d(0,12px,0);
                        width:100%;
                        height:auto;
                        display: block;
                        &.has-lightbox {
                            + .overlay {
                                cursor:pointer;
                                // &:hover {transition: opacity 0.5s; opacity: 1;}
                            }
                        }
                    }
                    @include brk($m) {
                        width:100%!important;
                    }
                    .overlay {
                        @extend .abs;
                        opacity: 0;
                        transition: opacity 0.3s;
                        background:rgba(0,0,0,0.1);
                        .icon {
                            width: 34px;
                            height: 34px;
                            position: absolute;
                            bottom:0;
                            right:0;
                            // transform:rotate(270deg);
                            .st0 {fill:#fff}
                        }
                    }
                }
                .col1 {width:23.2%;}
                .col2 {float:right;width:71.8%;}
            }
            &.opened {
                .bloc-contenu {transition: opacity 1.5s; opacity: 1}
                .bloc-titre .icon-container {
                    background:$touche_de_couleur;
                    .st0{stroke:#fff !important;}
                }
                .bloc-titre:hover .icon-container {
                    background:#e71e4b;
                }
                .icon-container .icon {transform: rotate(180deg);}
                .icon-container .vert {opacity:0}
            }
        }
    }
}





.flex-focus_fond_bleu {
    padding: 65px 0 60px;
    background:#051637;
    margin-top: -5px;
    margin-bottom: 80px;
    animation: fade 2s !important;
    @include brk($t) {
        padding: 65px 0 75px;
    }
    .content {
        position: relative;
    }
    .titres-container{
        width: 30%;
        float: left;
        padding-right:6%;
        margin-bottom: 20px;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        .titre {
            color:#fff;
            font-size: 25px;
        }
        .chapeau p {
            color:#92b5e2;
            font-weight: $light;
            line-height: 2;
        }
    }
    .liste-container {
        width: 40%;
        float: left;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        ul {
            margin-top: -13px;
            @include brk($t) {
                margin-top: -5px;
                margin-bottom: 10px;
            }
        }
        li{
            &:before {display:none}
            padding-left: 46px;
            &:last-child p {border:none!important}
            .icon {
                position: absolute;
                top:14px;
                left:8px;
                height:17px;
                width:17px;
                .st0{fill:#dd5953;stroke:#dd5953;stroke-width:7;stroke-linecap:square;}
            }
        }
        p {
            color:#fff;
            font-size: 16px;
            font-weight: $light;
            padding-bottom:17px;
            border-bottom:1px solid transparentize(#fff, 0.9);
        }
    }
    .btn-01 {
        background: #dd5953;
        border-radius:5px;
        position: absolute;
        right:0;
        @include brk($t) {
            position: relative;
            right:auto;
        }
        &:hover {
            background: darken(#dd5953, 15%);
        }
    }
}   





.flex-symptomes {
    text-align: center;
    background:#f8f8f8;
    padding:60px 0 67px;
    margin-bottom: 85px;
    .titre {
        padding-bottom: 17px;
    }
    .sous-titre {
        font-size: 14px;
        font-weight: $bold;
        color:$touche_de_couleur;
        margin-bottom: 55px;
    }
    .col {
        width:16.66%;
        @include brk($t) {
            width:33.33%;
            height:280px;
        }
        @include brk($m) {
            width:100%;
            height: auto;
            margin-bottom: 50px;
        }
        .image-container {
            position: relative;
            height:160px;
            width:160px;
            margin: 0 auto 27px;
            .image {
                border-radius:50%;
                overflow:hidden;
            }
            @include brk($t) {
                margin-bottom: 23px;
            }
        }
        .texte-container {
            p {
                font-size: 14px;
                text-align: center!important;
                font-weight: $regular;
                padding: 0 20px;
            }
        }
    }
}





