

// ACCUEIL HEIGHT BANNER TOP

$pgac_bT_h-desktop: 595px;
$pgac_bT_h-laptop: 595px;
$pgac_bT_h-tablet: 405px;
$pgac_bT_h-mobile: 480px;



.page-accueil .banner.top {
    // background: $footer-color;
    height:$pgac_bT_h-desktop;
    transition: height 1s;
    @include brk ($l) { height:$pgac_bT_h-laptop; }
    @include brk ($t) { height:auto; min-height:$pgac_bT_h-tablet; }
    @include brk ($m) { height:auto; min-height:$pgac_bT_h-mobile; }
    .bloc-container {
        line-height: $pgac_bT_h-desktop;
        @include brk ($l) { line-height:$pgac_bT_h-laptop; }
        @include brk ($t) { line-height:$pgac_bT_h-tablet; }
        @include brk ($m) { line-height:$pgac_bT_h-mobile; }
    }
}



.page-accueil .banner.top {
    .image {
        background-position:80% 50%;
        animation: fade 1.2s;
        @include brk($t) {
            background-position:65% 50%;
            height:405px;
        }
        @include brk($tp) {
            height:233px;
            background-position:85% 50%;
        }
    }
    .banner-content {
        
    }
    .bloc-container {
        @include brk($t) {
            // margin-top: 405px;
            height: auto;
        }
        @include brk($tp) {
            line-height: 1;
            margin-top: 233px;
        }
        .bloc {
            width:45%;
            // text-align: center;
            padding:0px 0% 0;
            @include brk($t) {
                width:53%;
                padding:40px 0% 40px 0;
            }
            @include brk($tp) {
                width:100%;
                text-align: center;
                padding:25px 0% 35px;
            }
            .textes {
                .sur-titre {
                    font-size: 22px;
                    animation: fade 1.5s;
                    color: transparentize(#fff, 0);
                    letter-spacing: 0.2px;
                    line-height: 2;
                    padding: 0 12%;
                    font-family: $family1;
                    font-weight: $light;
                    @include brk($t) {
                        font-size: 19px;
                        padding: 0 0;
                    }
                    @include brk($m) {
                        // display: none;
                    }
                }
                .titre {
                    clear: both;
                    animation: fade 1.5s;
                    color: transparentize(#003359, 0);
                    font-size: 52.5px;
                    font-weight: $bold;
                    letter-spacing: 0.3px;
                    word-spacing: 2px;
                    margin-bottom: 10px;
                    font-family: $family1;
                    position: relative;
                    display: inline-block;
                    // text-transform: uppercase;
                    line-height: 1.18;
                    @include brk($t) {
                        font-size: 40px;
                    }
                    @include brk($tp) {
                        font-size: 27.5px;
                        line-height: 1.18;
                        margin-bottom: 0px;
                    }
                    span {
                        color:$touche_de_couleur;
                        font-weight: $light;
                    }
                    &:before {
                        content:"›";
                        position: absolute;
                        top:-10px;
                        left:-46px;
                        font-size: 64px;
                        display: inline-block;
                        font-weight: $normal;
                        color:$touche_de_couleur;
                        @include brk($t) {
                            display:none;
                        }
                    }
                }
                .sous-titre p {
                    color:$touche_de_couleur;
                    font-size: 42.5px;
                    animation: fade 2s;
                    font-weight: $light;
                    line-height: 1.18;
                    @include brk($t) {
                        font-size: 32px;
                    }
                    @include brk($tp) {
                        font-size: 27.5px;
                        br {display:none;}
                    }
                }
                .lien {
                    margin-top: 40px;
                    background: $touche_de_couleur2;
                    animation: fade 2.5s;
                    @include brk($t) {
                        margin-top: 35px;
                    }
                    @include brk($tp) {
                        margin-top: 25px;
                        p {
                            padding-left:25px;
                            padding-right:85px;
                        }
                    }
                    &:hover {
                        background:#003359;
                    }
                    p {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}





// SLIDER ------------------------------------------

.page-accueil #home_top_slider {
    height: 100%;
    
}




















// VIGNETTES ------------------------------------------

.banner.vignettes {
    background: radial-gradient(#fff 0%, #fff 10%, #eaf5f6 45%, #eaf5f6);
    margin-top: -6px;
    @include brk($t) {
        height:auto;
        padding-bottom:40px;
        background: linear-gradient(#eaf5f6 0%, #eaf5f6 20%, #fff 50%, #eaf5f6 80%, #eaf5f6);
        // background: radial-gradient(#fff 0%, #fff 10%, #eaf5f6 45%, #eaf5f6);
    }
    @include brk($m) {
        padding-bottom:20px;
        background: #eaf5f6;
    }
    .bloc-titre-section {
        text-align: center;
        padding: 100px 0 120px;
        .titre-section {
            color:#003359;
            font-size: 19px;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        @include brk($t) {
            padding: 100px 0 50px;
        }
        @include brk($m) {
            padding: 35px 0 50px;
            .titre-section {
                font-size: 15px;
            }
        }
    }
    ul {
        text-align:center;
        position: relative;
        // animation: fade 1s;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &.by-four {

        }
    }
    li {
        width:33.33%;
        z-index:100;
        display: inline-block;
        height:403px;

        display: flex;
        flex-shrink: 3; /* default 1 */
        
        position: relative;
        padding-left:3px;
        float: left;
        margin-bottom: 20px;
        // position: absolute;
        // top:0;
        transition: left 1s, top 1s, transform 0.5s;
        &.ng-enter {
            opacity: 0;
            transform:translate3d(0,40px,0) scale(0.95);
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) { 
                    transition-property: transform, opacity, width;
                    transition-duration: 0.30s + (0.16s * $i), 1s, 2s;
                    transition-delay : ((0.15s * $i) - 0.15s), 0s; 
                }
            }
        }
        &.ng-enter-active {
            opacity: 1;
            transform:translate3d(0,0px,0) scale(1);
        }


        // &:nth-child(1) {left:0%;}
        // &:nth-child(2) {left:33.33%;}
        &.by-four {
            width: 25%;
            // &:nth-child(1) {left:0%;}
            // &:nth-child(2) {left:25%;}
            // &:nth-child(3) {left:50%;}
            // &:nth-child(4) {left:75%;}
        }

        &.by-five {
            &:nth-child(4) {float: none; clear:both;margin-top: 80px;}
            &:nth-child(5) {float: none; clear:both;margin-top: 80px;}
            &:nth-child(6) {float: none; clear:both;margin-top: 80px;}
            @include brk($t) {
                margin-top: 0 !important;
            }
        }

        @include brk($t) {
            position: relative;
            height:auto;
            width:calc(100%);
            text-align: left;
            // background: #fff;
            // &:nth-child(1) {}
            // &:nth-child(2) {top:430px;}
            // &:nth-child(3) {top:860px;}
            // &:nth-child(4) {top:1290px}
            // &:nth-child(1) {left:0%;}
            // &:nth-child(2) {left:50%;}
            // &:nth-child(3) {left:0%; top:430px;}
            // &:nth-child(4) {left:50%; top:430px;}
        }
        @include brk($m) {
            width: 100%;
            left:0% !important;
            padding-left:0px;
            text-align: center;
        }
        a {
            padding: 100px 15% 80px;
            position: relative;
            height:100%;
            transition: transform 0.3s;
            background-color: #fff;
            @include brk($t) {
                background-color: transparentize(#fff,1);
                padding: 10px 15% 50px 200px;
            }
            @include brk($m) {
                padding: 160px 12% 45px;
            }
        }
        .image-container {
            width:142px;
            height:142px;
            position: absolute;
            transition: transform 0.5s;
            top:-71px;
            left: calc(50% - 71px);
            animation: scale 1s;
            @include brk($t) {
                top:0;
                left:0% !important;
            }
            @include brk($m) {
                top:0;
                left: calc(50% - 71px) !important;
            }
            .image {
                border-radius:50%;
                overflow: hidden;
                width:100%;
                height:100%;
                animation:fade 1s;
                // animation: scale 2s;
            }
        }
        .bloc-titre {
            display: inline-block;
            .titre {
                font-size:27.5px;
                font-weight: $bold;
                position: relative;
                display: inline-block;
                padding-bottom: 30px;
                animation: fade 1s;
                @include brk($t) {
                    padding-top:10px;
                    padding-bottom: 10px;
                }
                @include brk($m) {
                    padding-top:0px;
                    padding-bottom: 5px;
                }
            }
            .trait {
                width:54px;
                height:3px;
                position: relative;
                margin: 25px auto 28px;
                transition: width 1s;
                animation: fade 1.5s;
                display: none;
            }
        }
        .texte {
            font-size:16px;
            position: relative;
            line-height: 1.6;
            color:#727272;
            font-weight: $normal;
            letter-spacing: 0px;
            animation: fade 2s;
        }
        .btn-container {
            position: absolute;
            width:100%;
            left:0;
            bottom:40px;
            animation: fade 2.5s;
            display: none;
            button {
                background-color:#c8c8c8;
                padding:10px 20px;
                border-radius: 7px;
                display: inline-block;
                width:158px;
                transition: background 0.5s, width 0.4s, border-radius 0.4s;
                p {
                    color:#fff!important;
                    font-size:14px;
                    font-weight: $normal;
                }
                .icon {
                    position: absolute;
                    width:20px;
                    height:20px;
                    bottom:12px;
                    left:calc(50% - 60px);
                    .st0{fill:#FFFFFF;}
                }
            }
        }
        // &:nth-child(1) a:hover {background: darken(#3b88c3, 25%)}
        // &:nth-child(2) a:hover {background: darken(#83ad3f, 25%)}
        // &:nth-child(3) a:hover {background: darken(#4cacb2, 25%)}
        // &:nth-child(4) a:hover {background: darken(#7c63a8, 25%)}
        &:hover {
            // .image-container { transform: scale(0.95); }z
            transform: translate3d(0,5px,0);
            @include brk($t) {
                transform: translate3d(0,0px,0);
            }

            // .titre {color:#fff;}
            // .texte {color:#fff;}
            button {
                width:165px;
                border-radius:0px;
                background:#fff;
                .st0 {fill:inherit!important}
                p {color:inherit!important;}
            }
        }
        &:nth-child(1) {
            .ombre-top,
            .ombre.side.right { display: none!important; }
        }
        &:nth-child(2) {

        }
        &:nth-child(3) {
            .ombre.side.left {
                display: none;
            }
        }
        .ombre {
            position: absolute;
            animation: fade 3s;
            background-size:contain;
            &.side {
                background-position: 100% 0%;
                width:40px;
                height:100%;
                top:0;
                bottom:0;
                background-image:url('../assets/images/ombre-vignette-side.png');
                @include brk($t) {
                    display: none;
                }
                &.left {
                    left:-40px;
                }
                &.right {
                    right:-40px;
                    transform: rotate(180deg);
                }
            }
            &.bottom {
                background-position: 50% 0%;
                width:100%;
                left:0;
                bottom:-59px;
                height:60px;
                background-image:url('../assets/images/ombre-vignette-bottom.png');
            }
        }
        .ombre-top {
            position: absolute;
            animation: fade 3s;
            background-size:contain;
            background-position: 50% 100%;
            width:calc(100% - 200px);
            opacity: 0.7;
            height:30px;
            left: 200px;
            right:0;
            top:-35px;
            background-image:url('../assets/images/ombre-vignette-tablet.png');
        }
    }
}
















// BANNER A PROPOS -----------------------------------------


.banner.apropos {
    background: #fff;
    margin-top: -140px;
    padding:200px 0 120px;
    @include brk($t) {
        margin-top: 0;
        padding:55px 0 65px;
    }
    @include brk($m) {
        padding:35px 0 0px;
    }
    .col1 {
        padding-right:9%;
        @include brk($t) {
            width:100%;
            margin-bottom: 55px;
        }
        @include brk($m) {
            margin-bottom: 95px;
        }
        .sur-titre {
            font-size: 11.5px;
            color: #404040;
            letter-spacing: 2px;
            margin-bottom: 15px;
            line-height: 2;
            font-family: $family1;
            font-weight: $bold;
            text-transform: uppercase;
            @include brk($t) {
                // font-size: 11.5px;
                padding: 0 0;
            }
            @include brk($m) {
                // display: none;
            }
        }
        .titre {
            clear: both;
            animation: fade 1.5s;
            color: transparentize(#003359, 0);
            font-size: 42.5px;
            font-weight: $bold;
            letter-spacing: 0.3px;
            word-spacing: 2px;
            margin-bottom: 40px;
            font-family: $family1;
            position: relative;
            display: inline-block;
            // text-transform: uppercase;
            line-height: 1;
            @include brk($t) {
                font-size: 42.5px;
            }
            @include brk($m) {
                font-size: 25px;
                line-height: 1.18;
                margin-bottom: 20px;
            }
            span {
                color:$touche_de_couleur;
                @include brk($t) {
                    display: block;
                }
            }
            &:before {
                content:"›";
                position: absolute;
                top:-16px;
                left:-46px;
                font-size: 64px;
                display: inline-block;
                font-weight: $normal;
                color:$touche_de_couleur;
                @include brk($t) {
                    left:-38px;
                }
                @include brk($m) {
                    display:none;
                }
            }
        }
        .texte {
            padding-bottom:29px;
            p {
                font-size: 16px;
                color:#727272;
            }
        }
        .liens {
            a {
                color:$touche_de_couleur;
                font-weight: $bold;
                font-size: 19px;
                padding: 3px 0;
                &:before {
                    content:"›";
                    margin-right: 7px;
                }
                &:hover {
                    color:$touche_de_couleur2;
                }
            }
        }
    }
    .col2 {
        height:494px;
        position: relative;
        @include brk($t) {
            width:calc(100% - 68px);
            height:493px;
        }
        @include brk($m) {
            width:120%;
            margin-left: -10%;
            height:284px;
        }
        .nav-control {
            position: absolute;
            width:110px;
            top:-52px;
            right:-57px;
            @include brk($m) {
                right:auto;
                left:4%;
                top:-60px;
            }
            .sep {
                display: inline-block;
                height:40px;
                width: 1px;
                position: absolute;
                top:5px;
                left:52px;
                background: transparentize(#000, 0.9);
            }
            .flex-prev, .flex-next {
                display: inline-block;
                height:40px;
                width:50px;
                line-height: 1;
                text-align: center;
                font-size: 40px;
                display: inline-block;
                color:$touche_de_couleur3;
                cursor: pointer;
            }
        }
        #home_slider, #home_slider_2 {
            width: 100%;
            overflow:hidden;
        }
        #home_slider, #home_slider_2, .slides, .slide, .slides .inside {
            height:494px;
            @include brk($t) {
                height:493px;
            }
            @include brk($m) {
                height:284px;
            }
        }
        #home_slider_2 {
            position: absolute;
            left:100%;
            top:0;
            bottom:0;
            opacity: 0.55;
        }
        .overlay {
            background: linear-gradient(to bottom, 
                transparentize(#000, 1), 
                transparentize(#000, 1) 50%,
                transparentize(#000, 0) 100%
                )
        }
        .legende {
            position: absolute;
            bottom:30px;
            left:0;
            right:0;
            color:#fff;
            text-align: center;
            z-index: 10;
            font-size: 16px;
            font-weight: $bold;
        }
    }
}




















// BANNER SLICK -------------------------------------------------

.banner.slick {
    height: 333px;
    background: linear-gradient(to bottom, #eaf5f6 0%, #fff 50%, #fff 100%);
    padding: 50px 0 40px;
    @include brk($m) {
        margin-top: -7px;
        padding: 50px 0 40px;
    }
    .titre-container {
        @include brk($m) {
            text-align: center;
        }
        .titre {
            clear: both;
            animation: fade 1.5s;
            color: transparentize(#003359, 0);
            font-size: 27.5px;
            font-weight: $bold;
            letter-spacing: 0.3px;
            word-spacing: 2px;
            margin-bottom: 50px;
            font-family: $family1;
            position: relative;
            display: inline-block;
            user-select:none;
            // text-transform: uppercase;
            line-height: 1;
            @include brk($t) {
                // font-size: 52.5px;
            }
            @include brk($m) {
                font-size: 24px;
                line-height: 1.25;
                margin-bottom: 40px;
                text-align: center;
            }
            span {
                color:$touche_de_couleur;
                 @include brk($m) {
                    display: block;
                }
            }
            &:before {
                content:"›";
                position: absolute;
                top:-23px;
                left:-46px;
                font-size: 64px;
                display: inline-block;
                font-weight: $normal;
                color:$touche_de_couleur;
                @include brk($t) {
                    top:-25px;
                    left:-38px;
                }
                @include brk($m) {
                    display:none;
                }
            }
        }
    }
    
    $slick_height: 120px;


    .slider {position: relative;}
    .slick-track, ul, .slick-track li {height: $slick_height}
    .slick-slide { margin: 0 20px; }
    .slick-list { margin: 0 -20px; }
    .slick-track li {
        height:$slick_height;
        // background: #fff;
        a.inside {
            height:$slick_height;
            line-height:$slick_height;
            border:1px solid rgba(0,0,0,0);
            transition: border 1s;
            .bloc {padding-bottom:10px;}
            &:hover {
                p:after {width: calc(100% - 60px);opacity:0.8}
            }
            &[href]:hover .logo {filter: saturate(100%)!important;}
        }
        .logo {
            width:100%;
            height:70px!important;
            margin: 0 auto;
            position: relative;
            z-index: 10;
            background-size:contain;
            background-position:50% 50%;
            // filter: saturate(0%);
            @include brk($td) {
                filter: saturate(100%);
            }
        }
        .avec-lien:hover {
            border:1px solid rgba(0,0,0,0.07);
        }
        .bloc-voir-le-temoignage {
            .icon {
                width:24px;
                height: 24px;
                display: inline-block;
                position: absolute;
                path {fill:$touche_de_couleur;}
            }
            p {
                padding-left:40px;
                padding-right:20px;
                padding-top:3px;
                padding-bottom:20px;
                display: inline-block;
                user-select:none;
                text-transform: uppercase;
                font-size: 10px;
                color:$touche_de_couleur;
                font-weight: $black;
                letter-spacing: 1.8px;
                position: relative;
                &:after {
                    content:"";
                    display: inline-block;
                    position: absolute;
                    width: 0%;
                    left:40px;
                    bottom:20px;
                    height:1px;
                    opacity: 0;
                    transition: width 0.1s, opacity 0.5s;
                    background: $touche_de_couleur;
                }
            }
        }
    }


    .slick-dots {
        width:50%;
        height:50px;
        z-index:10;
        position: absolute;
        right:0px;
        top:-70px;
        animation: fade 2s;
        @include brk ($t) {
            top:255px;
            width:300px;
            left:auto;
            left:calc(50% - 150px);
            text-align: center;
        }
        text-align: right;
        li, a {
            height:30px;
            width:40px;
            position: relative;
            display: inline-block;
        }
        li {
            cursor: pointer;
            @include brk($t) {
                text-align: center;
            }
        }
        a {text-align:center;cursor:pointer}
        .span-container {
            width:12px;
            height:12px;
            border-radius: 50%;
            display: inline-block;
            position: relative;
        }
        button {
            width:13px;
            height: 13px;
            color:rgba(0,0,0,0);
            // border-radius: 50%;
            transform: rotate(45deg);
            display: inline-block;
            background-color: rgba(0,0,0,0.1);
            position: absolute;
            top:0;
            left:14px;
            transition: background 1s;
        }
        .slick-active button {
            // background-color: rgba(255,255,255,0.8);
            background-color: $touche_de_couleur;
            
        }
        li:hover button {
            background-color: $touche_de_couleur;
        }
    }


}