


.lightbox-search-all-container {
    display: none;
    &.active {
        display: block;
    }
}

$search_overlay_opacity: 0.8;

.page-overlay.search {
    position: fixed;
    display: block;
    background: #000;
    opacity:$search_overlay_opacity;
    z-index:1999;
    &.ng-enter {
        opacity: 0;
        transition: opacity 0.3s;
        &.ng-enter-active { opacity:$search_overlay_opacity; }
    }
    &.ng-leave {
        opacity:$search_overlay_opacity;
        transition: opacity 0.35s;
        &.ng-leave-active { opacity: 0; }
    }
}

.lightbox.container {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:2000;
    overflow-y: scroll;
    width: calc(101.9%);
    cursor: pointer;
    @include brk ($t) {
        width: calc(100% + 25px);
    }
}

.lightbox.search {
    position: absolute;
    top:120px;
    left:50%;
    margin-left: - ($bannerContent-maxWidth-desktop / 2);
    z-index: 1000;
    display: inline-block;
    max-width:$bannerContent-maxWidth-desktop - 10px;
    min-height:600px;
    width:100%;
    overflow:hidden;
    transition: margin-top 1s;
    cursor: default;
    &.ng-enter {
        transition: transform 0.2s, opacity 0.15s; 
        transform:scale(0.85); opacity:0;
    }
    &.ng-enter-active {transform:scale(1); opacity:1}
    @include breakpoint ($tablet) {
        top:100px;
        width:calc(100% - 13px);
        left: 0%;
        margin-left: 0;
    }
    @include breakpoint ($mobile) {
        top:80px;
        width:calc(100% - 18px);
    }
    .banner-content {
        padding: 0 62px;
    }
    .inside {
        display: inline-block;
        min-height:300px;
        padding-top:50px;
        margin-bottom: 120px;
    }
    .nombre-de-resultats {
        margin-bottom: 15px;
        margin-left: 3px;
        position: absolute;
        top:10px;
        left:0;
        &.ng-enter {
            transition: opacity 0.5s; 
            opacity:0;
            &.ng-enter-active {opacity:1}
        }
        // animation: fade 1s;
        p {
            font-size: 11.5px;
            letter-spacing: 0.8px;
            color:rgba(255,255,255,0.5);
            font-weight: $bold;
            display: inline-block;
            &.nombre {
                margin-right: 5px;
            }
        }
    }
    ul {
        display: inline-block;
        width:100%;
        padding-top:50px;
    }
    li {
        display: block;
        width:100%;
        height:70px;
        &:last-child { border-top:none; }
        &.ng-enter {
            @for $i from 1 through 50 {
                &:nth-child(#{$i}) { 
                    transition-delay : (0.02s * $i); 
                }
            }
            transition: opacity 1s; 
            opacity:0;
            &.ng-enter-active {opacity:1}
        }
        &:hover {
            .date {color:rgba(255,255,255,0.7);}
            .titre {color:rgba(255,255,255,1);}
            a {
                transition: border 1s;
                border-bottom:1px solid rgba(255,255,255,0.5);}
        }
        a {
            padding: 25px 0 12px; 
            display: inline-block;
            height:100%;
            width:100%;
            transition: border 0.3s;
            border-bottom:1px solid rgba(255,255,255,0.1);
        }

        .date {
            color:rgba(255,255,255,0.5);
            float: left;
            display: inline-block;
            font-size: 11px;
            padding-top:9px;
            font-weight: $bold;
            letter-spacing: 0.5px;
            width:105px;
            height:20px;
            transition: color 0.5s;
            @include brk ($m) { width:90px; }
        }
        .titre {
            float: left;
            display: inline-block;
            transition: color 1s;
            font-size: 20px;
            line-height: 1.5;
            color:rgba(255,255,255,0.8);
            font-weight: $light;
            @include brk ($m) { font-size: 19px; }
        }
    }
}


.close-btn.search {
    display: inline-block;
    width:80px;
    height:80px;
    position: absolute;
    z-index: 1100;
    top:-5px;
    right:0px;
    cursor:pointer;
    animation: fade 4s;
    .icon {
        float: right;
        display: block;
        width:20px;
        height:20px;
        margin: 0 auto;
        margin-top: 28px;
        .st0{
            stroke:#fff;
            fill:none;stroke-width:2.5;stroke-miterlimit:10;}
    }
    // @include breakpoint ($mobile) {
    //     height:60px;
    //     width:60px;
    // }
    &:hover {
        transition: background 1.8s;
        .st0{fill:none;stroke-width:4.5;stroke-miterlimit:10;}
    }
}







// ================================================================================
/*    SEARCH FIELD
==================================================================================*/


.lightbox.search .search-field {
    height:60px;
    // background:rgba(255,255,255,0.6);
    display: inline-block;
    width:99%;
    position: relative;
    z-index: 99;
    animation: fade 2s;
    @include brk ($m) {
        width:100%;
        height:65px;
    }
    input {
        height:100%;
        color:rgba(255,255,255,1);
        width: 100%;
        user-select:text;
        font-size: 23px;
        letter-spacing: 1px;
        padding-left:40px;
        font-weight:  $light;
        transition: border 0.5s;
        border-bottom: 1px solid rgba(255,255,255,0.3);
        @include brk ($m) {font-size:20px;}
        &:focus {
            border-bottom: 1px solid rgba(255,255,255,0.45);
        }
    }
    .icon-magnifying {
        position: absolute;
        left:-10px;
        top:11px;
        width: 40px;
        height: 40px;
        transition: transform 0.5s;
        svg {transform:scale(0.55); transition: transform 0.5s;}
        path {fill:rgba(255,255,255,0.4);fill-rule:evenodd;clip-rule:evenodd;}
    }

    $placeholder_FONT_SIZE: 23px;
    $placeholder_FONT_SIZE_MOBILE: 20px;
    $placeholder_FONT_WEIGHT: $light;
    $placeholder_TEXT_ALIGN: left;
    $placeholder_COLOR: rgba(255,255,255,0.75);
    $placeholder_TRANSITION: opacity 0.4s;
    $placeholder_FOCUS_OPACITY: 0.6;

    ::-webkit-input-placeholder { /* WebKit browsers */
        color: $placeholder_COLOR;
        font-weight: $placeholder_FONT_WEIGHT;
        text-align: $placeholder_TEXT_ALIGN;
        font-size: $placeholder_FONT_SIZE;
        transition: $placeholder_TRANSITION;
        @include breakpoint ($mobile){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color: $placeholder_COLOR;
       font-weight: $placeholder_FONT_WEIGHT;
       text-align: $placeholder_TEXT_ALIGN;
       font-size: $placeholder_FONT_SIZE;
       transition: $placeholder_TRANSITION;
       opacity:  1;
       @include breakpoint ($mobile){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: $placeholder_COLOR;
       font-weight: $placeholder_FONT_WEIGHT;
       text-align: $placeholder_TEXT_ALIGN;
       font-size: $placeholder_FONT_SIZE;
       transition: $placeholder_TRANSITION;
       opacity:  1;
       @include breakpoint ($mobile){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }

    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
       color: $placeholder_COLOR;
       font-weight: $placeholder_FONT_WEIGHT;
       text-align: $placeholder_TEXT_ALIGN;
       font-size: $placeholder_FONT_SIZE;
       transition: $placeholder_TRANSITION;
       @include breakpoint ($mobile){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }
    }
    .placeholder{
        font-size: $placeholder_FONT_SIZE;
        text-align: $placeholder_TEXT_ALIGN;
        color: $placeholder_COLOR;
        font-weight: $placeholder_FONT_WEIGHT;
        opacity: 1;
    }
    input:focus {
        
        &::-webkit-input-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
        &:-moz-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
        &::-moz-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
        &:-ms-input-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
    }
}





