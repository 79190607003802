$ft: $t + 0px;
$fm: $m + 0px;





footer {
    overflow-y: hidden;
    background: $footer-color;
    #footer_top {
        padding-top:40px;
        padding-bottom:30px;
        @include brk($fm) {
            text-align: center;
            padding-top:30px;
        }
        .banner-content {
            @include brk($ft) {
                padding-left: 7%;
                padding-right: 7%;
            }
            @include brk($fm) {
                padding-left: 6%;
                padding-right: 6%;
            }
        }
    }
    #footer_bottom {
        .content {
            padding-top: 8px;
            text-align: center;
            border-top:1px solid transparentize(#fff, 0.94);
        }
    }
    .titre-section {
        color:#fff;
        font-size: 20px;
        font-weight: $bold;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        user-select:none;
    }
}





// VERY TOP CONTAINER -----------------------------------------

footer .very-top-container {
    height:100px;
    width: 100%;
    border-bottom:1px solid transparentize(#fff, 0.94);
    margin-bottom: 50px;
    @include brk($tp) { 
        height:auto;
        text-align: center;
        padding-bottom:20px;
        margin-bottom: 10px;
    }
    &:hover .btn-01 {
        background:$touche_de_couleur2;
    }
    .texte-container {
        padding-top:9px;
        p {
            font-size: 30px;
            color:#fff;
            text-transform: uppercase;
            letter-spacing: 0;
            position: relative;
            user-select:none;
            line-height: 1.2;
            @include brk($ft) { 
                font-size: 25px;
                margin-bottom: 20px;
            }
            @include brk($tp) { 
                margin-bottom: 30px;
            }
            @include brk($fm) { 
                font-size: 21px;
                margin-bottom: 20px;
            }
            &:before {
                content:"›";
                position: absolute;
                top:-24px;
                left:-50px;
                font-size: 60px;
                display: inline-block;
                color:$touche_de_couleur;
                @include brk($ft) { 
                    left:-40px;
                }
                @include brk($tp) { 
                    display: none;
                }
            }
            span {
                margin-left: 4px;
                color:$touche_de_couleur;
                font-weight: $bold;
                @include brk($ft) { 
                    display: block
                }
            }
        }
    }
    .btn-01 {
        position: absolute;
        top:8px;
        right:0;
        @include brk($tp) { 
            position: relative;
            top:auto;
            right: auto;
            display: inline-block;
            margin-bottom: 20px;
        }
        @include brk($fm) { 
            margin-bottom: 0px;
        }
        p {
            padding-top:20px;
            text-transform: uppercase;
            font-size: 15px;
        }
        .icon {
            top:13px;
            padding-top:0;
        }
    }
}






// COORDONNES -----------------------------------------

footer .bloc-coordonnees {
    width:47%;
    float: right;
    @include brk($ft) { width: 68% }
    @include brk($tp) { width: calc(100% - 180px) }
    // @include brk($ft) { width: calc(100% - 300px) }
    @include brk($fm) { 
        text-align: left;
        width:100%; 
        margin-bottom: 0px;
    }
    >ul {
        display: inline-block;
        width: 100%;
        @include brk($tp) { 
            width: 110%;
        }
        @include brk($fm) { 
            width: 100%;
        }
        >li {
            display: inline-block;
            width: 50%;
            float: left;
            padding-right: 20px;
            @include brk($fm) { 
                width: 100%;
                &:not(:last-child){
                    margin-bottom: 35px;
                };
            }
            .col {width: 100%}
            p, a {
                font-size: 15px;
                color:#fff;
                line-height: 2;
                font-weight: $light;
                @include brk($td) {
                    font-size: 14px;
                }
                span {
                    font-weight: $bold;
                }
            }
            .col1 {
                margin-bottom: 22px;
                .rue, .ville, .pays {
                    color:$touche_de_couleur;
                }
            }
            span {
                display:block;
                color:$touche_de_couleur;
                a {padding-left:5px;}
            }
            a {position: relative;}
            a:after {
                content:"";
                display: inline-block;
                position: absolute;
                bottom:3px;
                left:5px;
                height:2px;
                width:0;
                opacity: 0;
                transition: opacity 0.5s, width 0.2s;
                background: lighten($touche_de_couleur, 30%);
            }
            a:hover {
                &:after {
                    width:calc(100% - 5px);
                    opacity: 1;
                }
            }
        }
    }
    
}



// MENU -------------------------------------------------------

footer .menu-footer {
    float: left;
    width:140px;
    margin-bottom: 15px;
    @include brk($fm) {
        text-align: left;
        float: none;
        width: 100%;
        margin-bottom: 50px;
    }
    .titre-section {
        span {
            color:$touche_de_couleur2;
        }
    }
    >ul>li {
        &:first-child {
            a { border-top:none; }
        }
        &:hover, &.current-menu-item, &.current_page_ancestor {
            a { color:transparentize(#fff, 0); }
        }
    }
    >ul>li>a {
        color:transparentize(#fff, 0.1);
        font-size: 16px;
        padding:6px 0px;
        width: 100%;
        border-top: 1px solid transparentize(#fff, 0.9);
        @include brk($td) {
            font-size: 15px;
        }
        &:before {
            content:"›";
            margin-right: 5px;
        }
    }
    >ul>li>ul {display:none;}
}






// COPYRIGHT LINE ----------------------------------


footer .copyright-line {
    display:inline-block;
    text-align:center;
    @include brk($fm){width:100%;}
    p, a, span {
        color:rgba(255,255,255,0.5);
        // text-transform: uppercase;
        font-size: 9.5px;
        letter-spacing:1px;
        transition: color 0.6s;
    }
    a {
        padding: 15px 15px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        text-transform: uppercase;
        @include brk($fm){
            padding: 5px 10px;
            &.respect {
                display: block;
            }
        }
        &:hover {color:#eee;}
        &:first-child {
            border-bottom:none;
            &:before {display:none;}
        }
        &:before {
            content:"";
            display: inline-block;
            position: absolute;
            top:14px;
            left:-4px;
            width:1px;
            height:15px;
            background:rgba(255,255,255,0.15);
            @include brk($fm){display:none;}
        }
    }
    p {
        display:inline;
    }
}







// SOCIAL ICONS  -----------------------------------------

footer .social-container {
    position: absolute;
    right:0;
    top:16px;
    @include brk($ft) {
        transform: translate3d(0,10px,0);
        position: relative;
        display: inline-block;
        top:auto;
        right:auto;
        text-align: center;
    }
    @include brk($fm) {
        transform: translate3d(0,0px,0);
        margin-top: 15px;
        text-align: center;
        margin-bottom: 10px;
    }

    .icon-container {
        .icon {
            margin-left: 10px;
            width:28px;
            height:28px;
        }
        .st0 {fill:$touche_de_couleur}
        .st1 {fill:$footer-color;}
        &:first-child {
            margin-left: 0px;
        }
        &:hover {
            .st0 {fill:darken(#0d1221, 25%)}
            .st1 {fill:lighten(#fff, 20%);}
        }
    }
}






footer .bloc-newsletter {
    float: left;
    margin-right: 7%;
    width: 330px;
    @include brk($ft) {
        width: 100%;
        padding-bottom:45px;
        margin-bottom: 60px;
        border-bottom:1px solid transparentize(#fff, 0.94);
    }
    @include brk($fm) {
        text-align: center;
        padding-bottom:0px;
        margin-bottom: 20px;
    }
    .titre-section {
        @include brk($ft) {
            display: inline-block;
            float: left;
            width:120px;
            margin-bottom: 0;
            margin-top: 15px;
        }
         @include brk($fm) {
            float: none;
        }
    }
    .texte-newsletter {
        font-size: 15px;
        color:$touche_de_couleur;
        font-weight: $normal;
        letter-spacing: 0.5px;
        user-select:none;
        width: 286px;
        line-height: 1.6;
        margin-bottom: 40px;
        span {
            letter-spacing: 0.5px;
            color:$white;
            font-weight: $normal;
        }
        @include brk($ft) {
            display: none;
            margin-bottom: 22px;
        }
        @include brk ($fm) {
            width:280px;
            margin: 0px auto 23px;
            text-align: center;
        }
    }
    form {
        margin-top: 20px;
        position: relative;
        width:100%;
        // background:#f9f9f9;
        border: 1px solid transparentize(#fff, 0.8);
        // float: left;
        height:66px;
        @include brk($ft) {
            width:calc(100% - 140px);
            float: right;
            margin-top: 0px;
        }
        @include brk ($fm) {
            width:280px;
            float: none;
            margin: 20px auto 30px;
        }
    }
    input {
        height:100%;
        padding:0px 20px 0px 20px;
        width:calc(100% - 73px);
        color:rgba(255,255,255,1);
        float: left;
        font-weight: $normal;
        // border-radius:12px;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: left;
        transition: border 0.5s;
        // border:1px solid rgba(255,255,255,0.2);
        &:focus {
            // border:1px solid rgba(255,255,255,0.3);
        }
        @include brk ($fm) {
        }
    }
    button {
        color:rgba(255,255,255,0.9);
        // border:1px solid rgba(255,255,255,0.2);
        // border-radius:50%;
        width:48px;
        margin-top: 7px;
        margin-right: 6px;
        height:calc(100% - 14px;
        float: right;
        font-size: 10px;
        font-weight: $black;
        transition: color 0.1s, background 0.1s, border 0.3s;
        letter-spacing: 1.5px;
        &:hover {
            color:rgba(255,255,255,1);
            background: $touche_de_couleur;
        }
        @include brk ($fm) {
        }
    }


    .response_box {
        float: left;
        clear: both;
        font-size: 15px;
        color:rgba(255,255,255,1);
        font-weight: $regular;
        font-style: italic;
        margin-top:12px;
        width:calc(100% - 73px);
        text-align: left;
        padding-left: 21px;
        @include brk($ft) {margin-top:8px;}
        &.success {color:green;}
        &.error {color:red;}
        display: none;
    }

        
    // FIX FOR CHROME AUTOFILL
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #2a2a2a inset;
        -webkit-text-fill-color: rgba(255,255,255,0.8) !important;
        border-bottom:1px solid #2a2a2a;
        &:focus {border-bottom:1px solid #143467!important;}
    }


    /**************  PLACEHOLDER ADJUST   ***************/

    $placeholder_FONT_SIZE: 14px;
    $placeholder_FONT_SIZE_MOBILE: 14px;
    $placeholder_FONT_WEIGHT: $normal;
    $placeholder_TEXT_ALIGN: left;
    $placeholder_COLOR: rgba(255,255,255,0.8);
    $placeholder_TRANSITION: opacity 0.3s;
    $placeholder_FOCUS_OPACITY: 0.25;


    ::-webkit-input-placeholder { /* WebKit browsers */
        color: $placeholder_COLOR;
        font-weight: $placeholder_FONT_WEIGHT;
        text-align: $placeholder_TEXT_ALIGN;
        font-size: $placeholder_FONT_SIZE;
        transition: $placeholder_TRANSITION;
        @include brk($fm){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color: $placeholder_COLOR;
       font-weight: $placeholder_FONT_WEIGHT;
       text-align: $placeholder_TEXT_ALIGN;
       font-size: $placeholder_FONT_SIZE;
       transition: $placeholder_TRANSITION;
       opacity:  1;
       @include brk($fm){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: $placeholder_COLOR;
       font-weight: $placeholder_FONT_WEIGHT;
       text-align: $placeholder_TEXT_ALIGN;
       font-size: $placeholder_FONT_SIZE;
       transition: $placeholder_TRANSITION;
       opacity:  1;
       @include brk($fm){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }

    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
       color: $placeholder_COLOR;
       font-weight: $placeholder_FONT_WEIGHT;
       text-align: $placeholder_TEXT_ALIGN;
       font-size: $placeholder_FONT_SIZE;
       transition: $placeholder_TRANSITION;
       @include brk($fm){
            font-size:$placeholder_FONT_SIZE_MOBILE;
        }
    }

    input:focus {
        &::-webkit-input-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
        &:-moz-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
        &::-moz-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
        &:-ms-input-placeholder {
            opacity: $placeholder_FOCUS_OPACITY;
        }
    }

}



