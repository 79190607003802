/* ---------------------------------------------------------------------------
    1. BASE
    2. OUTILS
    3. MENUS
    4. ELEMENTS
    5. PAGES
--------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------
   1. BASE
--------------------------------------------------------------------------- */
@import "base/reset";
@import "base/1__breakpoint";


/* ---------------------------------------------------------------------------
   2. OUTILS
--------------------------------------------------------------------------- */
@import "outils/1__variables";
@import "outils/2__format";
@import "outils/3__classes";
@import "outils/4__big-divs";
@import "outils/5__animations";


/* ---------------------------------------------------------------------------
   3. MENUS
--------------------------------------------------------------------------- */
@import "nav/header";
@import "nav/mobile";
@import "nav/nav-horizontal";
@import "nav/nav-others";
@import "nav/nav-side";
@import "nav/headroom";


/* ---------------------------------------------------------------------------
   4. ELEMENTS
--------------------------------------------------------------------------- */

@import "elements/slick";
@import "elements/flexslider";
@import "elements/justified-gallery";
@import "elements/photoswipe-lightbox";
@import "elements/lightbox-search";
@import "elements/footer";
// @import "elements/selectize/selectize.legacy";
@import "elements/selectize/selectize.bootstrap3";
@import "elements/commun";
@import "elements/flexible";

/* ---------------------------------------------------------------------------
   5. PAGES
--------------------------------------------------------------------------- */

@import "pages/page";
@import "pages/page-benefices";
@import "pages/page-accueil";
@import "pages/page-contact";
@import "pages/page-contact-form";

