


.btn-01 {
    background: $touche_de_couleur;
    padding:0!important;
    display: inline-block;
    &:hover {
        background: darken($touche_de_couleur, 15%);
        .icon span {transform:translate3d(1px,0,0);}
    }
    p {
        color:#fff;
        font-weight: $bold;
        font-size: 14px;
        padding:18px 90px 18px 30px;
        // display: inline-block;
        user-select:none;
        letter-spacing: 1.5px;
        // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
    }
    .icon {
        font-size: 30px;
        color:#fff;
        width:52px;
        text-align: center;
        font-weight: $normal;
        height:34px;
        position: absolute!important;
        top:13px;
        line-height: 1;
        right:6px;
        border-left:1px solid rgba(255,255,255,0.3);
        span {
            transition: transform 0.5s;
            display: inline-block;
            // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
    }
    &:after {
        display: none!important;
    }
}









.scroll-btn {
    width:62px;
    height:62px;
    position: absolute;
    bottom:-31px;
    left:calc(50% - 31px);
    z-index: 30;
    text-align: center;
    cursor: pointer;
    @include brk($tp) {
        width:52px;
        height:52px;
        bottom:-26px;
        left:calc(50% - 26px);
        display: none;
    }
    &.ng-enter {
        opacity: 0;
        transform: translate3d(0,40px,0);
        transition: opacity 0.4s, transform 0.6s;
        &.ng-enter-active {
            opacity: 1;
            transform: translate3d(0,0px,0);
        }
    }
    &.ng-leave {
        opacity: 1;
        transform: translate3d(0,0px,0);
        transition: opacity 0.7s, transform 2s;
        &.ng-leave-active {
            opacity: 0;
            transform: translate3d(0,145px,0);
        }
    }
    .back-icon {
        content:"";
        width:100%;
        height:100%;
        top:0;
        left:0;
        transform: translate3d(-20px, -20px, 0);
        background:$touche_de_couleur;
        border-radius: 50%;
        opacity: 0;
        position: absolute;
        z-index: -1;
        @keyframes pumpIt {
            0% {
                transform: scale(1);
                opacity: 0;
            }
            50% {
                opacity: 0.15;
            }
            100% {
                opacity: 0;
                transform: scale(1.8);
            }
        }
        animation-delay: 0.5s;
        animation-iteration-count: infinite;
        animation-duration: 3s;
        animation-name: pumpIt;
        // animation: pumpIt 2s;
    }
    .icon {
        background: $touche_de_couleur;
        width:100%;
        height:100%;
        border-radius:50%;
        transition: transform 0.5s, background 0s;
        svg {
            width:20px;
            height:20px;
            display: inline-block;
            margin-top: 21px;
            .st0{fill:#FFFFFF;}
        }
        @include brk($m) {
            svg {
                margin-top: 19px;
                width:16px;
                height:16px;
            }
        }
    }
    &:hover {
        .icon {
            transform: scale(1.08);
            background: darken(#003359, 0%)!important;
        }
    }
}

