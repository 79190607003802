

.page.single.benefices .banner.top {
	.icon-solution {
        position: absolute;
        width:105px;
        height:105px;
        border-radius: 50%;
        right:0;
        bottom:-52px;
        z-index: 10;
        overflow: hidden;
        background:none !important;
        @include brk($m) { 
            width:60px;
            height:60px;
            bottom:-30px;
        }
        .icon {
            animation: fade 1s;
            width: 100% !important;
            height:100% !important;
            position: absolute;
            top:0px !important;
            left:0px !important;
            background-size: cover;
        }
    }
}