





nav.nav_side {
	@include brk($t) {
		padding-top:20px;
	}
	@include brk($m) {
		display: none;
	}
	>ul>li>a, >ul>li:not(.current-menu-parent)>ul>li, >ul>li:not(.current-menu-parent)>ul {
        display: none;
    }

    >ul>li {
    	>ul {

    	}

    	>ul>li {
    		display: inline-block;
    		border-top:1px solid transparentize(#000, 0.9);
    		&:first-child {&, span {border-top:none;}}
    		&.has-image {
                border-top:none;
                a { padding:0; }
            }
            &:hover {
                a {
                    color:$touche_de_couleur2;
                }
            }
            @include brk($t) {
                width: 100%;
            }

        }

        >ul>li>a {
            font-size:15px;
            font-weight: $bold;
            display: inline-block;
            padding:18px 0;
            letter-spacing: 0.1px;
            line-height: 1.6;
            width:265px;
            color: #003155;
            position: relative;
            @include brk($t) {
                width: 100%;
            }
            >img {
                height:auto;
                width:25px;
                float: left;
                margin-right: 20px;
                margin-top: 19px;
            }
            span {
                padding:18px 0;
                display: inline-block;
                width:calc(100% - 45px);
                border-top:1px solid transparentize(#000, 0.9);
            }
    	}
    	>ul>li.current-menu-item a {
    		font-weight: $bold;
    		color:$touche_de_couleur;
    		&::after {
		        content:"›";
		        position: absolute;
		        font-size:28px;
		        font-weight: $normal;
		        color:$touche_de_couleur;
		        top:5px;
		        right:0px;
		        // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.1);
		    }
    	}
    }
}