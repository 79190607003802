
$headroom_background_fade_out: 1s;
$headroom_background_opacity: 0.96;

$headroom_speed_in: 0.6s;
$headroom_speed_out: 0.8s;


@keyframes slideUp_home {
    0% { transform: translateY(0); }
    100% { transform: translateY(- 199px); }
}
@keyframes slideUp_home_tablet {
    0% { transform: translateY(0); }
    100% { transform: translateY(- 48px); }
}
@keyframes slideUp_home_mobile {
    0% { transform: translateY(0); }
    100% { transform: translateY(- 61px); }
}

@keyframes slideDown_home{
    0% { transform: translateY(- 199px); }
    100% { transform: translateY(0); }
}
@keyframes slideDown_home_tablet {
    0% { transform: translateY(- 48px); }
    100% { transform: translateY(0); }
}
@keyframes slideDown_home_mobile {
    0% { transform: translateY(- 61px); }
    100% { transform: translateY(0); }
}

@keyframes slideUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(- 143px); }
}
@keyframes slideUp_tablet {
    0% { transform: translateY(0); }
    100% { transform: translateY(- $header-height-tablet); }
}
@keyframes slideUp_mobile {
    0% { transform: translateY(0); }
    100% { transform: translateY(- $header-height-mobile); }
}

@keyframes slideDown {
    0% { transform: translateY(- 143px); }
    100% { transform: translateY(0); }
}
@keyframes slideDown_tablet {
    0% { transform: translateY(- $header-height-tablet); }
    100% { transform: translateY(0); }
}
@keyframes slideDown_mobile {
    0% { transform: translateY(- $header-height-mobile); }
    100% { transform: translateY(0); }
}



header#header>.background {
    background: #fff;
}


header#header.headroom {
    will-change: transform;
    animation-duration: $headroom_speed_in;
    animation-fill-mode: both;
    // animation-timing-function: ease-in-out;
    .background {
        transition: opacity $headroom_background_fade_out;
        opacity: $headroom_background_opacity;
    }
    &.headroom--top {
        .background {
            // opacity: 0!important;
            transition: opacity $headroom_background_fade_out;
        }
    }
    &.headroom--pinned {
        &:not(.page) {
            animation-name: slideDown_home;
            @include brk($t) {
                animation-name: slideDown_home_tablet;
            }
            @include brk($m) {
                animation-name: slideDown_home_mobile;
            }
        }
        &.page {
            animation-name: slideDown;
            @include brk($t) {
                animation-name: slideDown_tablet;
            }
            @include brk($m) {
                animation-name: slideDown_mobile;
            }
        }
        .background {
            transition: opacity $headroom_background_fade_out;
            opacity: $headroom_background_opacity;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        &:not(.page) {
            animation-name: slideUp_home;
            @include brk($t) {
                animation-name: slideUp_home_tablet;
            }
            @include brk($m) {
                animation-name: slideUp_home_mobile;
            }
        }
        &.page {
            animation-name: slideUp;
            @include brk($t) {
                animation-name: slideUp_tablet;
            }
            @include brk($m) {
                animation-name: slideUp_mobile;
            }
        }
        .background {
            opacity: $headroom_background_opacity;
        }
    }
}



#hamburger_container.headroom, #calling_button.headroom {
    will-change: transform;
    animation-fill-mode: both;
    &.headroom--top {
    }
    &.headroom--pinned {
        animation-duration: $headroom_speed_in;
        animation-name: slideDown_home;
        &.page {
            animation-name: none;
        }
        @include brk($t) {
            animation-name: slideDown_home_tablet;
        }
        @include brk($m) {
            animation-name: slideDown_home_mobile;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp_home;
        &.page {
            animation-name: none;
        }
        @include brk($t) {
            animation-name: slideUp_home_tablet;
        }
        @include brk($m) {
            animation-name: slideUp_home_mobile;
        }
    }
}

