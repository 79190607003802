div, time, div, a, p, input, li, h1, h2, h3, h4, h5, h6 {
	font-family: $family1;
	color:$color_default;
}


a, p, h1, h2, h3, h4, h5, h6 {
	line-height:1.65; 
	font-weight:400;
	letter-spacing: 0.3px;
	word-spacing: 1px;
}

p, h1, h2, h3, h4, h5, h6 {user-select:text}
a, p {font-size:16px}
h1 {font-size:30px}
h2 {font-size:26px}
h3 {font-size:22px}
h4 {font-size:16px}
h5 {font-size:15px}
h6 {font-size:14px}





//  FORMAT CLASS
// ______________________________________




$format__p__font_size: 16px;
$format__p__font_weight: $normal;
$format__p__color: darken(#727272, 0%);
$format__p__letter_spacing: 0.5px;
$format__p__word_spacing: 0px;
$format__p__line_height: 1.8;
$format__p__padding_top: 0px;
$format__p__padding_bottom: 20px;

$format__a__font_weight: 	$normal;
$format__a__color: 			#003359;

$format__h__font_weight : $bold;
$format__h__color : #202020;
$format__h__letter_spacing : 1.5;
$format__h__padding_top: 0px;
$format__h__padding_bottom: 25px;



.format {
	img.alignleft {
		float: left;
		margin: 5px 40px 20px 0;
	}
	img.alignright {
		float: right;
		margin: 5px 0 20px 40px;
	}
	img.aligncenter {
		margin: 20px auto 15px;
		display: block;
	}
	user-select:text;
	p, li, a, h1, h2, h3, h4, h5, h6 {
		color:$grey;
	}
	a {
		font-size: $format__p__font_size;
		line-height: $format__p__line_height;
		color: $format__a__color;
		letter-spacing: $format__p__letter_spacing;
		word-spacing: $format__p__word_spacing;
		font-weight: $format__a__font_weight;
		// font-style:italic;
		margin-left: 2px;
		margin-right: 2px;
		position: relative;
		&:after {
			content:"";
			display: inline-block;
			border-bottom: 2px solid rgba(0,0,0,0.5);
			position: absolute;
			height:1px;
			left:0;
			bottom:3px;
			width:0;
			opacity: 0;
			transition: width 0.2s, opacity 0.8s;
		}
		&:hover:after {
			color:#000;
			width:100%;
			opacity:1;
		}
	}
	p {
		font-size: $format__p__font_size;
		line-height: $format__p__line_height;
		color: $format__p__color;
		letter-spacing: $format__p__letter_spacing;
		word-spacing: $format__p__word_spacing;
		font-weight: $format__p__font_weight;
		padding-top: $format__p__padding_top;
		padding-bottom: $format__p__padding_bottom;
		@include brk($m) {
			text-align: left!important;
		}
	}
	blockquote p {
		color:#434343;
		text-align:center;
		font-size:23px;
		font-weight:300;
		letter-spacing:1.2px;
		line-height:1.8;
		padding-top:20px;
		padding-bottom:35px;
	}
	h2, h3, h4 {
		font-weight: $format__h__font_weight;
		color:$format__h__color;
		line-height:1.6;
		padding-top:$format__h__padding_top;
		padding-bottom:$format__h__padding_bottom;
		letter-spacing: $format__h__letter_spacing;
	}
	li {
		font-size: $format__p__font_size;
		font-weight: $format__p__font_weight;
		color: $format__p__color;
		line-height:1.9;
		list-style-type:none;
		// display: table-row;
		margin-bottom:10px;
		vertical-align: top;
		padding-left:15px;
		position: relative;
		letter-spacing: $format__p__letter_spacing;
		word-spacing: $format__p__word_spacing;
		// &::after {content:"";display:table-row;height:10px;}
	}
	ul, ol {
		padding-top:10px;
		padding-bottom:20px;
		display:table;
	}
	ul {
        li:before {
            content: '›';
            color: $format__p__color;
            font-weight: $bold;
            position: absolute;
			left: 0px;
			top: 0px;
        }
    }
    ol {
	    // list-style-position: inside;
	    margin-left:20px;
	    counter-reset: foo;
	    li {
	        counter-increment: foo;
	        padding-left:17px;
	        &::before {
	        	content: counter(foo) ".";
	        	position: absolute;
				left: 2px;
				top: 0px;
	        }
	    }
	}
}