



.page.single {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 155px;
$page_wrapper-bannerTop-laptop: 155px;
$page_wrapper-bannerTop-tablet: 155px;
$page_wrapper-bannerTop-mobile: 155px;



.page.single .banner.top {
    background:#fff;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.single .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.page.single .banner.top {
    position: relative;
    // background:#051637;
    .image {
        background-position: 50% 50%;
    }
    .bloc {
        @include brk($t) {
            padding: 0px 0 0 0;
            // text-align: center;
        }
    }
    .sur-titre {
        // animation: fade 2.5s;
        font-size: 35px;
        margin-bottom: 0px;
        color:#003359;
        // text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: $bold;
        position: relative;
        z-index: 4;
        line-height: 1.55;
        @include brk($t) { 
        }
        @include brk($m) { 
            font-size: 27.5px; 
        }
    }
    .titre {
        // animation: fade 2s;
        font-size:20px;
        line-height: 1.1;
        font-weight: $light;
        letter-spacing: 0.5px;
        color:$touche_de_couleur;
        position: relative;
        z-index: 4;
        @include brk($m) { font-size: 15px; }
    }
    .icon-solution {
        position: absolute;
        width:105px;
        height:105px;
        background:$touche_de_couleur;
        border-radius: 50%;
        right:0;
        bottom:-52px;
        z-index: 10;
        @include brk($m) { 
            width:60px;
            height:60px;
            bottom:-30px;
        }
        .icon {
            animation: fade 1s;
            width: 64px;
            height:64px;
            position: absolute;
            top:21px;
            left:21px;
            @include brk($m) { 
                width:36px;
                height:36px;
                top:13px;
                left:12px;
                
            }
        }
    }
}






.page.single {
    .banner.medium {
        background: #ffffff;
        padding-bottom:70px;
        padding-top:70px;
        min-height: 500px;
        @include brk($t) {
            padding-top:50px;
        }
        @include brk($m) {
            padding-top:30px;
            min-height: 100px;
        }
        .col-left {
            width:30%;
            @include brk($t) {
                width:100%;
            }
        }
        .col-right {
            width:68%;
            @include brk($t) {
                width:100%;
            }
        }
    }
}

















// BANNER GALERIE -----------------------------------------------------


.banner.galerie {
    background:#ffffff;
    margin-top: 0px;
    margin-bottom: 20px;
    .banner-content {
        padding:0;
    }
    .content {
    }
    .titre-section {
        font-size: 25px;
        color:$touche_de_couleur3;
        font-weight: $bold;
        margin-bottom: 10px;
        @include brk($m) {
            font-size: 22px;
        }
    }
    .sous-titre {
        text-align: left;
        font-size: 15.5px;
        font-weight: $light;
        line-height: 1.5;
        margin-bottom: 7px;
        @include brk($m) {
            font-size: 15px;
        }
    }

    .justified {
        animation: fade 3s;
        #gallery {
            width:calc(100% + 10px);
            margin-left: -5px;
        }
        a {
            cursor: pointer;
            animation-fill-mode: none !important;
            img {
                transition: filter 0.3s;
                filter:brightness(90%);
            }
            .caption {
                font-family: $family1;
                display: block;
                opacity: 1!important;
                background-color: transparentize(#000, 0.3);
                // display:none!important;
            }
            span.description {display:none;}
            &:hover {
                img{filter:brightness(100%) contrast(105%);}
            }
        }
    }
}
