
// FORM BACKGROUND 
$contact_form_background: #ecf7f8;

$box_shadow: 1px 1px 4px 0px transparentize(#000, 0.95);

// PADDING-LEFT
$field_padding_left: 44px !important;


.contact-form {
    margin-top: 0px;
    margin-bottom:100px;
    padding: 0 10% 0 0;
    display: inline-block;
    width: 100%;
    min-height: 600px;
    // overflow:hidden;
    @include breakpoint ($tablet) {
        padding:0;
    }
    
    .sur-titre {
        font-size: 21px;
        padding-bottom:15px;
        font-weight: $bold;
        color:#104b99;
        @include breakpoint ($mobile) {
            padding-right: 20%;
        }
    }
    form {
        width:100%;
        max-width: 900px;
        margin-top: 60px;
        min-height:280px;
        margin-bottom: 45px;
        animation: fade 2s;
        @include breakpoint ($tablet) {
            margin-top: 55px;
            max-width: none;
        }
        @include breakpoint ($mobile) {margin-top: 50px;}
        label {
            display: inline-block;
            font-size: 16px;
            margin-bottom:20px;
            letter-spacing: 0.5px;
            color:$touche_de_couleur3;
            font-weight: $bold;
            display: none;
        }
        .container {
            display: inline-block;
            width:100%;
            margin-bottom: 10px;
            position: relative;
            input, textarea, select, .selectize-input {
                width:100%;
                padding:0 20px;
                z-index: 10;
                font-size: 19px;
                letter-spacing: 1px;
                transition: border 0.5s, box-shadow 0.15s, padding-left 0.3s;
                border:1px solid rgba(0,0,0,0);
                border-radius:12px;
                font-weight: $normal;
                color:$touche_de_couleur3;
                background:#fff;
                box-shadow: $box_shadow;
                &.ng-pristine {
                    // border:1px solid #fff!important;
                }
                &:focus {
                    box-shadow: 2px 2px 4px 1px transparentize(#000, 0.9) !important;
                }
            }
            input {
                will-change: box-shadow, background, padding-left;
                position: relative;
                font-weight: $bold;
            }
            input, select, .selectize-input {
                height:54px;
            }
            // FIX FOR CHROME AUTOFILL
            input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0px 1000px #fff inset, $box_shadow !important;
                -webkit-text-fill-color: rgba(0,0,0,0.9) !important;
                border: 1px solid #fff;
                &:focus {
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset, 2px 2px 4px 1px transparentize(#000, 0.9) !important;
                }
            }
            $form_half_width: 49.15%;
            &.email, &.city {width:$form_half_width;float:left;}
            &.telephone, &.postcode {width:$form_half_width;float:right;}
            @include breakpoint ($mobile) {
                &.email, &.city {width:100%;}
                &.telephone, &.postcode {width:100%;}
            }
        }
        textarea {
            resize:none!important;
            padding-top:20px!important;
            box-shadow:none;
            min-height: 150px;
            &:focus { min-height: 170px; }   
            padding-top:30px!important;
            padding-right: $field_padding_left;
            height:auto;
            font-weight: $bold !important;
            transition: border 0.3s, height 0.5s, min-height 0.5s, padding-left 0.3s !important;
            // width: 100%;
            // display: inline-block;
            max-height:500px;
            transition: height 1s;
            // line-height: 2;
            text-align: justify;
            @include brk($m) {
                text-align: left;
            }
        }
        .container.message {
            margin-bottom: 18px;
        }
        .champs-requis {
            text-align: right;
            margin-bottom: 0px;
            float: right;
            p {
                font-size: 14px;
                font-weight: $light;
                font-style: italic;
                color:$touche_de_couleur;
            }
            @include brk($m) {display:none;}
        }
        button[type="submit"] {
            // position: absolute;
            // bottom:50px;
            // left:15%;
            width:250px;
            text-align: left;
            padding: 0 0 0 40px;
            background:$touche_de_couleur;
            font-weight: $bold;
            // border-radius:12px;
            overflow:hidden;
            margin-top: 10px;
            color:#fff;
            height:60px;
            font-size:15px;
            letter-spacing: 1px;
            // transition: background 0.2s;
            // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
            @include breakpoint ($mobile) {width:100%;}
            &:hover, &:focus {
                background: $touche_de_couleur2;
            }
            &.message-sent {
                background: green!important;
                p {
                    padding-right:20px;
                    &.sent {animation: fade 0.5s;}
                }
            }
        }
    }
}







// INPUT SELECT  (Selectize) --------------------------------


.contact-form {
    .container.you_are {
        margin-bottom: 30px;
        z-index: 50;
        @include brk($m) {
            margin-bottom: 5px;
        }
        label {
            margin-top: 15px;
            display: inline-block;
            position: relative;
            z-index: -1;
        }
        .selectize-control {
            width:calc(100% - 115px);
            float: right;
            @include brk($m) {
                float: none;
                width:100%;
            }
            .selectize-input {
                box-shadow:none!important;
                input {display:none !important;}
                &:after {
                    height:22px;
                    width:22px;
                    border:none;
                    top:18px;
                    right:30px;
                    margin-top: 0;
                    background:url('../assets/images/select-dropdown.svg');
                }
                .item {
                    padding-top:12px;
                    color:$touche_de_couleur;
                }
            }
            .selectize-dropdown {
                overflow: hidden;
                .selectize-dropdown-content {
                    padding:0;
                    .option {
                        color:$touche_de_couleur;
                        padding: 10px 12px;
                    }
                }
            }
        }
    } 
}











.response_box-container {
    position: relative;
    overflow: hidden;
    height:40px;
    // background: #eee;
    &.ng-enter {
        transition: opacity 1.5s, height 1s; 
        height:0; 
        opacity:0; 
        &.ng-enter-active {
            opacity:1; 
            height:40px; 
        }
    }
    &.ng-leave {
        transition: opacity 2.5s, height 2.5s; 
        height:40px; 
        opacity:1; 
        &.ng-leave-active {
            opacity:0; 
            height:0px; 
        }
    }
    .response_box {
        display: inline-block;
        position: absolute;
        top:0;
        left:0;
        font-size: 14px;
        width:100%;
        letter-spacing: 0px;
        font-style: italic;
        &.please-wait {color:#333}
        &.success {color:$touche_de_couleur}
        &.error {color:red}
    }
}














// TOOLTIP   ---------------------------------

$tooltip_red: mix(red, #b46c6b, 28%);
$tooltip_orange: mix(orange, #dbb36d, 15%);

@keyframes show_tooltip {
    0% {opacity:0.8; transform: scaleY(0) translateY(30px)}
    70% {transform: scaleY(1) translateY(-1px)}
    100% {opacity:1; transform: scaleY(1) translateY(0px)}
}
@keyframes show_tooltip_triangle {
    0% {transform: translateY(-20px)}
    20% {transform: translateY(-20px)}
    100% {transform: translateY(0px)}
}


form.ng-submitted {
    input.focus-submitted + .tooltip,
    textarea.focus-submitted + .tooltip {display:inline-block;}
}

form .tooltip {
    position: absolute;
    top:-49px;
    left:19px;
    will-change: transform, opacity;
    box-shadow: 0px 0px 4px 1px transparentize(#000, 0.91) !important;
    transform: translate3d(0,0,0);
    display:none;
    z-index: 20;
    animation: show_tooltip 0.25s;
    span {
        padding:15px 19px;
        display: inline-block;
        color:#fff;
        font-size: 12px;
        animation: fade 0.2s;
        cursor:default;
        &::first-letter { text-transform: uppercase; }
    }
    >div {
        // border-top-right-radius:2px;
        // border-top-left-radius:2px;
    }
    >div::after {
        content:"";
        transform: translate3d(0,0,0);
        animation: show_tooltip_triangle 0.3s;
        position: absolute;
        left:0;
        bottom:-9px;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid green;
    }
    [ng-message="required"] {
        background: $tooltip_red;
        &::after {border-left-color:$tooltip_red;}
    }
    [ng-message="pattern"] {
        background: $tooltip_orange;
        &::after {border-left-color:$tooltip_orange;}
    }
}





// BACKGROUND CHANGE -----------------------------------

.contact-form form.ng-submitted {
    input.ng-invalid,
    textarea.ng-invalid {
        background:mix($contact_form_background, $tooltip_red, 91%)!important;
    }
    input.ng-invalid-pattern,
    textarea.ng-invalid-pattern {
        background:mix($contact_form_background, $tooltip_orange, 85%)!important;
    }
}












// REQUIRED ICONS ---------------------------------


.page.contact form {
    .container {
        &.input .icon {
            height:100%; 
        }
        .icon {
            position: absolute;
            z-index: 20;
            top:0.5px;
            will-change: opacity, transform;
            &.ng-enter {
                opacity:0;
                transform:scale(0);
                transition: opacity 0.25s 0.05s, transform 0.2s 0.05s;
                &.ng-enter-active {
                    transform:scale(1);
                    opacity:1; 
                }
            }
        }
        .icon-checked {
            width:14px;
            left:19px;
            .st0 {fill:transparentize(green, 0)}
        }
        .icon-circle {
            width:9px;
            left:20px;
            .st0{fill:mix(orange, $tooltip_orange, 90%)}
        }
        .icon-cross {
            width:10px;
            left:19px;
            .st0{
                fill:none;
                stroke:mix(red, $tooltip_red, 60%);
                stroke-width:9;
                stroke-miterlimit:10;
            }
        }
        &.textarea {
            .icon-checked {top: 32.5px;}
            .icon-circle, .icon-cross { top: 31.5px;}
        }
    }

    input {
        &.prefill,
        &.ng-invalid.cross-has-shown,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-valid.blured.ng-dirty,
        &[disabled="disabled"] {
            padding-left: $field_padding_left;
        }
    }

    textarea {
        &.ng-valid, &.cross-has-shown {
            padding-left: $field_padding_left;
        }
    }

    &.ng-submitted {
        input, textarea {
            &.ng-invalid-pattern {
                padding-left: $field_padding_left;
            }
            &[type="tel"], &[type="email"] {
                &.ng-valid {
                    padding-left: $field_padding_left;
                }
            }
        }
    }
}






// WHEN FORM IS COMPLETE & SUBMITTED  ->  DISABLED -------------------

$disabled_color: transparentize($touche_de_couleur3, 0.7) !important;

.contact-form form[disabled="disabled"] {
    .container input, .container textarea {
        color: $disabled_color;
        user-select:none;
        cursor: default;
        padding-left:44px;
        &:disabled:-webkit-autofill {
            -webkit-text-fill-color: $disabled_color;
        }
    }
}








.contact-form {

/**************  PLACEHOLDER ADJUST   ***************/

    input::placeholder, textarea::placeholder {
        font-size: 19px;
        font-weight: $normal;
        color: transparentize(#143467, 0.5) !important;
        text-align: left;
        letter-spacing: 0px;
        // transition: opacity 0.3s;
        opacity: 1;
        // z-index: 10;
        // line-height: 1;
        @include brk($td){ font-size: 18px }
        @include brk($m){  font-size: 17px }
    }
    input:focus, textarea:focus {
        &::placeholder { opacity: 0.5!important; }
    }

}