



#header_behind {
    height:$header-height + 48px;
    background: #fff;
    position: relative;
    @include brk($t) {height:$header-height-tablet + 48px;}
    @include brk($m) {height:$header-height-mobile + 61px;}
    &.page {
        background: #fff;
        height:$header-height;
        @include brk($t) {height:$header-height-tablet;}
        @include brk($m) {height:$header-height-mobile;}
    }
}


#header {
    height:$header-height + 48px;
    position:fixed;
    top:0px;
    left:0px; 
    z-index: 99;
    background:$white;
    // border-bottom: 1px solid rgba(255,255,255,0.15);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
    @include brk($t) {
        height:$header-height-tablet + 48px;
    }
    @include brk($m) {
        height:$header-height-mobile + 61px;
    }
    &.page {
        background:none;
        height:$header-height;
        @include brk($t) {
            height:$header-height-tablet;
        }
        @include brk($m) {
            height:$header-height-mobile;
        }
    }
    .banner-content {
        height: $header-height;
        margin: 0 auto;
        @include brk($header-getLarge) {
            margin:0; 
            padding:0 0px 0 0px;
            transition: 0.5s;
        }
        @include brk($t) {height:$header-height-tablet;}
        @include brk($m) {height:$header-height-mobile;}
    }
}



#focus_home {
    height:48px;
    text-align: center;
    padding-top:13px;
    position: relative;
    @include brk($m) {
        height:61px;
        padding-top:10px;
    }
    p, a {
        color:#fff;
        font-size: 13px;
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        animation: fade 2s;
        @include brk($m) {
            font-size: 11px;
        }
    }
    p {
        @include brk($m) {
            padding: 0 10%;
        }
    }
    a {
        position: relative;
        margin-left: 4px;
        &:after {
            content:"";
            position: absolute;
            height:1px;
            width: 100%;
            bottom:0;
            left:0;
            background: #fff;
            @include brk($m) {
                bottom:-2px;
            }
        }
        &:hover {
            &:after {
                height:2px;
                bottom:-1px;
                @include brk($m) {
                    bottom:-3px;
                }
            }
        }
    }
}


#header .logo-container {
    float: left;
    width: 206px;
    height: 100%;
    position: relative;
    display: inline-block;
    box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.2);
    &:hover {
        transition: box-shadow 0.3s;
        box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.26);
    }
    @include brk($m) {
        width: 183px;
        box-shadow: none !important;
    }
    .logo-image {
        // background: url('../images/logo.png');
        // background: url('../images/logo.svg');
        // background-size:contain;
        display: inline-block;
        height:100%;
        width:166px;
        position: absolute;
        top:1px;
        left:25px;
        .st0{fill:none;}
        .st1{fill:#00A9B9;}
        .st2{fill:#F18700;}
        @include brk($t) {
            left:24px;
            width:166px;
        }
        @include brk($m) {
            left:20px;
            width:140px;
        }
    }
}







#header .btn-container {
    position: absolute;
    height: 58px;
    bottom:-58px;
    right:0;
    width:100%;
    @include brk($t){display:none;}
    .btn-01 {
        display: inline-block;
        float: right;
        margin-left: 5px!important;
        // animation: fade 1s;
        box-shadow: inset 0px 33px 33px -30px rgba(0, 0, 0, 0.4);
        p {
            font-size: 14px;
            text-transform: uppercase;
        }
        &.devis {
            background:#00a8b8;
            &:hover {
                background:$touche_de_couleur2;
            }

        }
        &.client {
            background:#003359;
            &:hover {
                background:#00a8b8;
            }
            .icon {
                svg {transform: scale(0.65);}
                .st0{fill:#fff;}
            }
        }
    }
}










#nav_desktop {
    display:inline-block;
    height:100%;
    float:right;
    @include brk($t) {display:none;}
    >ul {
        list-style-type:none;
        height:100%;
        user-select: none;
    }
    >ul>li {
        display:inline-block;
        height:$header-height;
        line-height:$header-height;
        margin-left:-5px;
        user-select: none;
        position: relative;
        &.selected, &.current-menu-item, &.current_page_ancestor {
        }
        &:hover, &.current-menu-item, &.current_page_ancestor {
            background:#ecf7f8;
            &:before, & + li:before {display:none;}
            >a {
                color:transparentize(#003358,0);
            }
        }
    }
    >ul>li>a {
        display: inline-block;
        padding: 27px 18px 20px;
        // animation: fade 1.5s;
        font-size: 15px;
        letter-spacing: 0.6px;
        color: transparentize(#003358,0.1);
        font-weight: $semi-bold;
        text-transform: uppercase;
        height:100%;
        user-select: none;
        &:hover {color:transparentize(#003358,0);} 
    }




    // HOME ICON ---------------------------------

    >ul>li:first-child {
        position:relative; width:57px;
        a::after {
            content:"";
            width:21px;
            height: 21px;
            display: inline-block;
            position:absolute;
            top:30px;
            left:18px;
            cursor:pointer!important;
            background:url('../assets/images/icon-home-2.svg');
        }
        a {color: rgba(0,0,0,0);}
    }




    // SOULIGNÉ ----------------------- 

    // >ul>li:not(.btn)>a:before {
    //     content:"";
    //     width:0px;
    //     opacity: 0;
    //     transition: width 0.2s, opacity 0.5s, background 0.3s;
    //     height:5px;
    //     position: absolute;
    //     display: inline-block;
    //     background:$touche_de_couleur;
    //     left:18px;
    //     bottom:-1px;
    // }
    // >ul>li:not(.btn):hover >a:before,
    // >ul>li:not(.btn).current-menu-ancestor >a:before,
    // >ul>li:not(.btn).current-menu-item >a:before {
    //     opacity: 1;
    //     width:calc(100% - 35px);
    // }



    // SEPARATION VERTICALE -----------------

    >ul>li:before {
        content:"";
        width:1px;
        height:55px;
        position: absolute;
        display: inline-block;
        background:transparentize(#003358, 0.94);
        top:15px;
        left:0px;
    }
    >ul>li:first-child:before {display:none;}




    // FLECHE PARENT -----------------

    // >ul>li.menu-item-has-children {
    //     position: relative;
    //     a {padding-right:30px;}
    //     a:after {
    //         content:"";
    //         width:7px;
    //         height:8px;
    //         display: inline-block;
    //         position:absolute;
    //         top:38px;
    //         right:17px;
    //         cursor:pointer!important;
    //         background:url('../assets/images/parent-arrow.svg');
    //     }
    // }


}




//  LEVEL 2   ------------------------------------


#nav_desktop >ul>li {
    &:hover ul {
        // display:block;
    }
    >ul {
        position:absolute;
        left:0px;
        top:$header-height;
        margin-left: -22px;
        margin-top: -6px;
        display: none;
        padding: 0 60px 60px 40px;
    }
    >ul>li {
        background:#fff;
        display:inline-block;
        float:left;
        height:100%;
        border-top: 1px solid transparentize(#000, 0.9);
        transition: border 1s;
        // &:last-child {padding-right:0}
        // &.selected, &.current-menu-item a, &:hover a{border-top:3px solid $white;}
        &:hover {
            background:#eee;
            &, & + li {
                transition: border 0.5s;
                border-top: 1px solid transparentize(#000, 1);
            }
            >a {
                color:#173c73;
            }
        }
        &.current-menu-item>a {
            color:#173c73;
            font-weight: $bold;
        }
        >a {
            height:100%;
            width:290px;
            padding: 30px 0px;
            font-size:14px;
            letter-spacing: 1.5px;
            border-top:3px solid #333333;
        }
    }
    // &.current_page_ancestor>ul {display:inline-block;}
    >ul>li.menu-item-has-children.current-menu-item ul{display:block}
    >ul>li>ul {
        display: block;
        position:absolute;
        top:100px;
        left:0px;
        height:100px;
        display:none;
        background:$level3; width:40000px;
        >li {
            display:inline-block;
            float:left;
            height:100%;
            &:first-child a {padding-left:0}
            a {
                padding-top:42px;
                display:inline-block;
                font-size:13px;
                font-weight: 700;
                color:#333333;
                &:hover {color:$dark-grey;}
            }
        }
    }
}






#header .icon-search-container {
    width:55px;
    height: $header-height;
    line-height: $header-height;
    margin-left: 28px;
    float: right;
    @include brk($t){display:none;}
    &:hover {
        .icon {background:$touche_de_couleur2}
    }
    .icon {
        background:#c8d6d8;
        width: 55px;
        height: 55px;
        margin-bottom: 2px;
        @extend .bloc;
        svg {
            transform: scale(0.45);
            path {fill:#fff;}
        }
    }
}






#header .social-icons {
    // background:green;
    height:100%;
    float:right;
    animation: fade 2.5s;
    margin-right: -24px;
    @include brk ($tablet) {display:none;}
    .icon-container {    
        float: left;
        height:$header-height;
        line-height:$header-height;
        transition: height 1s, line-height 1s;
        width:80px;
        position: relative;
        text-align: center;
        .icon {
            width: 37px;
            height: 37px;
            @extend .bloc;
            margin-bottom: 7px;
            svg {
                transform: scale(0.8);
                transition: transform 0.5s;
            }
            .st0 {
                fill:#c9c9c9;
                transition: fill 0.3s;
            }
            .st1 {
                fill:#fff;
            }
        }
        &:hover {
            .icon svg { transform: scale(1);}
            .icon .st0 {fill: $touche_de_couleur2}
            // .icon .st0 {fill: rgba(255,255,255,1)}
        }
        &.search {
            cursor: pointer;
            .icon {
                width: 30px;
                height: 30px;
                transition: transform 0.5s, background 0.6s;
                background:$touche_de_couleur;
                border-radius:50%;
                svg {transform:scale(0.55)}
                path {fill:#fff;}
            }
        }
        &.search:hover {
            .icon.search {
                transform: scale(1.2);
                svg { transform: scale(0.5);}
            }
        }
        &.facebook:hover {
            // .icon .st0 {fill: #3B5998}
        }
    }
}
















// WPML -----------------------------------------------------


#header .wpml-ls-legacy-dropdown {
    height: $header-height;
    position: relative; 
    display: inline-block;
    float:right;
    padding-left:7px;
    &:before {
        content:"";
        width:1px;
        height:55px;
        position: absolute;
        display: inline-block;
        background:transparentize(#003358, 0.94);
        top:15px;
        left:0px;
    }
    @include breakpoint ($t) {
        display: none;
    }
    >ul {
        height:$header-height;
    }
    a {
        font-size: 15px;
        letter-spacing: 0.6px;
        color: transparentize(#003358,0.1);
        font-weight: $semi-bold;
    }
    >ul>li>a {
        // animation: fade 2s;
        text-transform: uppercase;
        height:$header-height;
        padding:  30px 16px 10px 12px;
        &:focus { border:none; }
        span {
            position: relative;
            &::after {
                content:"";
                width:8px;
                height:8px;
                position:absolute;
                top:10px;
                // animation: fade 1s;
                right:-14px;
                background-size:contain!important;
                background: url('../assets/images/wpml_down.svg')
            }
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        position:absolute;
        // height:100%;
        top:68px;
        left:-5px;
        z-index: 100;
        padding: 0 20px 20px;
    }
    >ul>li>ul>li {
        height: 40px; 
        width: 40px;
        background:#eeeeee;
        // border-radius:50%;
        margin-bottom: 7px;
        &:hover {background:$white;}
    }
    >ul>li>ul>li>a {
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
        height:100%;
        text-align: center;
        padding-top:8px;
        // border-radius:50%;
    }
}
