
/* NAV TABLET
====================*/




// LEVEL 1
$bg-l1: #fff;
$bg-l1-top: $touche_de_couleur;
$bg-l1-robe: darken($touche_de_couleur3, 5%);


// LEVEL 2
$bg-l2: darken(#fff, 0%);
$bg-l2-top: darken($touche_de_couleur3, 0%);
$bg-l2-robe: darken($touche_de_couleur3, 0%);


// LEVEL 3
$bg-l3: darken(#4cacb2, 6%);
$bg-l3-top: $dark-red;





.btn-home {
    z-index: 1012;
    position: absolute;
    display: inline-block;
    top:0;
    left:0;
    height:70px;
    width:70px;
    background:$white;
    animation: fade 1.5s;
    svg {transform:scale(0.4)}
    path {
        fill: #000;
        fill-rule: evenodd;
     }
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
}





// CONTAINER ------------------------------------

@keyframes open_menu {
    0% { transform:translate3d(100%, 0px, 0px); }
    100% { transform:translate3d(0, 0px, 0px); }
}


#nav_mobile_container {
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s, opacity 0.3s;
    z-index: 990;
    height:100%;
    top:0px;
    right:0;
    overflow: hidden;
    position:fixed;
    display: none;
    user-select:none;
    &.opened {
        display: block;
        animation: open_menu 0.3s;
    }
    @include brk ($t) {width:320px;}
    @include brk ($m) {max-width:calc(100% - 68px);}
}




// LEVEL 1 --------------------------------------------

#nav_mobile {
    height:100%;
    overflow: hidden;
    background:$bg-l1-robe;
    >ul {
        margin-top:$header-height;
        @include brk($t) {margin-top:$header-height-tablet;}
        @include brk($m) {margin-top:$header-height-mobile;}
    }
    >ul li:first-child {
        padding-top:5px; 
        a {border:none!important;}
    }
    >ul li a {
        line-height: 1.8;
        font-size:15px;
        position:relative;
        padding: 20px 0 20px 30px;
        letter-spacing: 2px;
        animation: fade 1.5s;
        font-weight: $bold;
        // text-transform: uppercase;
        border-top:1px solid transparentize(#000,0.9);
        transition: border 0.6s;
    }
    >ul>li {
        background:$bg-l1;
    }
    >ul li {
        &:hover, &.current-menu-item, &.current-menu-ancestor {
            background:darken($bg-l1, 6%);
            >a, + li>a {border-top:1px solid transparentize(#000,1);}
        }
    }
    >ul>li>a {
        display: inline-block;
        position: relative;
        width:100%;
        color:$touche_de_couleur3;
    }
    >ul>li.menu-item-has-children>a::after {
            content:"›";
            position: absolute;
            font-size:28px;
            font-weight: $normal;
            height:100%;
            box-sizing: inherit;
            color:$touche_de_couleur;
            width:62px; 
            top:0px;
            right:0px;
            padding-left:25px;
            padding-top: 4px;
            // background:#edecec;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.15);
            @include brk($m) {}
        }
}






// LEVEL 2 --------------------------------------------


#nav_mobile >ul>li {
    ul {
        z-index: 1012;
        top:$header-height;
        @include brk($tablet) {top:$header-height-tablet;}
        @include brk($mobile) {top:$header-height-mobile;}
        li>a {
            letter-spacing: 1px;
            color:$touche_de_couleur3;
            display: block;
            opacity: 0.1;
            transition: opacity 0.35s;
            position: relative;
            line-height: 1.55;
            padding: 17px 40px 17px 30px;
            >img {
                height:auto;
                width:25px;
                position: absolute;
                top:16px;
                left:20px;
                margin-top: 0px;
            }
        }
        &.opened {
            transform: translate3d(7.4%, 0px, 0px);
            >li>a {
                opacity: 1;
            }
        }
    }
    >ul>li {
        background:$bg-l2;
        &.has-image {
            a {
                padding: 17px 40px 17px 63px;
            }
        }
    }
    >ul {
        background:$bg-l2-robe;
        overflow-y: scroll;
        overflow-x: hidden;
        height:100%;
        width: 108.1%;
        position:absolute;
        right:1px;
        transform:translate3d(108%, 0px, 0px);
        transition: transform 0.3s;
    }
    >ul>li {
        &.current-menu-item, &:hover {
            background:darken($bg-l2, 3%);
            >a {color:$touche_de_couleur;}
            >a, + li>a {
                border-top:1px solid transparentize(#fff,1);
            }
        }
    }
}






// LEVEL 3 ---------------------------------------------

#nav_mobile >ul>li>ul>li{
    ul{
        transform:translate3d(0px, 0px, 0px);
        transition: transform 0.3s;
        background:$bg-l3;
        display:none;
        position:fixed;
        height:100%;
        a {
            color:#333333;
            font-weight: 700;
            &:hover {
                background:darken($bg-l3, 6%);
            }
        }
    }
}










@import "hamburger/00_hamburger";
#hamburger_container {
    position: fixed;
    animation: fade 1s;
    width:$header-height; 
    height:$header-height;
    z-index: 1010;
    right:0;
    top:48px;
    margin-right: - ($header-height + 50px);
    &.active {
        // animation:none!important
        background: $touche_de_couleur;
    }
    button {
        transition: transform 0.5s, background 0.5s;
        background: $touche_de_couleur;
    }
    button.is-active {
        transition: transform 0.5s, background 0.5s;
        transform: translate3d(0,-48px,0);
        background:#003359;
        @include brk($m) {
            transform: translate3d(0,-61px,0);
        }
    }
    &.page {
        top:0!important;
        button {
            transform: translate3d(0,0,0)!important;
        }
    }
    @include brk($t) {
        display:inline-block;
        margin-right: 0;
        width:$header-height-tablet; 
        height:$header-height-tablet;
    }
    @include brk($m) {
        top:61px;
        width:$header-height-mobile; 
        height:$header-height-mobile;
    }
    // button {
    //     &:before {
    //         content:"";
    //         @extend .abs;
    //         background:url('../assets/images/sandwish-gradient.png');
    //         height:80%;
    //         background-size:cover;
    //         background-position:0% 0%;
    //         opacity: 1;
    //     }
    // }
}






// TOP LINE NAMES

.bar-top {
    display: block;
    height:$header-height-tablet;
    line-height: $header-height-tablet;
    position:absolute;
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s;
    top:0px;
    right:0px;
    width:100%;
    p {
        padding-left: 30px;
        text-transform: uppercase;
        font-size:11px;
        color:$white;
        letter-spacing: 2px;
        display: inline-block;
        vertical-align: middle;
        line-height: normal!important;
    }
    &::after {content:none;}
    &.l1 {
        background:$bg-l1-top;z-index: 1011;
    }
    &.l2 {
        background:$bg-l2-top;z-index: 1013;
    }
    &.l3 {
        background:$bg-l3-top;z-index: 1015;
    }
    &.l2, &.l3 {
        transform:translate3d(100%, 0px, 0px);
    }
    @include brk($m) {
        height:$header-height-mobile;
        line-height: $header-height-mobile;
    }
}







#nav_mobile .social-icons.mobile {
    height:60px;
    width:100%;
    margin-right: 10px;
    display:none;
    background:#dcdcdc;
    padding-top: 12px;
    padding-left:20px;
    @include brk ($t) {display:inline-block;}
    .icon {
        border-radius:50%;
        width:24px;
        height:24px;
        float: left;
        animation: fade_scale 1.2s;
        margin-left: 6px;
        margin-right: 10px;
        margin-top: 6px;
        path {
            transition: fill 0.3s;
            &.st0 {
                fill: #222d62;
                &:hover {
                    fill: darken(#222d62, 10%);
                }
            }
            &.st1 {
                fill:rgba(255,255,255,1);
            }
        }
        rect {fill:rgba(255,255,255,0.8);}
        svg { transform:scale(1.25) }
    }
}






#calling_button {
    height:$header-height-mobile;
    width:$header-height-mobile;
    background: #003359;
    position:fixed;
    right:$header-height-mobile;
    top:48px;
    z-index: 100;
    animation: fade 1s;
    display: none;
    svg {
        width:21px;
        height:21px;
        top:20px;
        margin-top: 23px;
        margin-left: 24px;
        path {fill: #fff}
    }
    &:hover {
        background:darken($touche_de_couleur2, 5%);
    }
    &.page {
        top:0!important;
    }
    @include brk($t) {display: inline-block;}
    @include brk($m) {top:61px}
}









#nav_mobile .icon-search-container {
    position: absolute;
    top:0;
    left:0;
    height:$header-height-tablet;
    width:$header-height-tablet;
    background:#0b8e9a;
    .icon {
        margin-top:5px;
        width: 40px;
        height: 40px;
        transition: transform 0.5s, background 0.6s;
        svg {transform:scale(0.55)}
        path {fill:#fff;}
    }
}




#nav_mobile .btn-01 {
    width: 100%;
    &.devis {
        &:hover {
            background:$touche_de_couleur2;
        }
    }
    &.client {
        background:#003359;
        .icon {
            svg {transform: scale(0.65);}
            .st0{fill:#fff;}
        }
        &:hover {
            background:darken(#003359, 10%);
        }
    }
}








// WPML -----------------------------------------------------


.wpml-ls-statics-footer.wpml-ls-legacy-list-horizontal {
    background:$touche_de_couleur;
    position: absolute;
    // height:100%;
    overflow: hidden;
    padding-left: $header-height-tablet + 20px;
    @extend .bar-top;
    >ul>li {
        display: inline-block;
    }
    >ul>li>a {
        display: inline-block;
        height:100%;
        color:#fff;
        font-size: 15px;
        font-weight: $bold;
        padding: 23px 11px;
        text-transform: uppercase;
    }
}


